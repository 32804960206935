import VideoCarousel from "../../../components/ui/VideoCarousel";
import live1 from "../../../assets/images/svg/RadarPage/live1.svg";
import useGetWelcomeVideos from "../../../hooks/useGetWelcomeVideos";
import React from "react";
import PropTypes from "prop-types";

const ViralComponent = ({ page }) => {
  const data = useGetWelcomeVideos({ page: page, limit: 10, type: "Viral100" });

  if (!data || data.length === 0) {
    return null;
  }

  return (
    <div>
      <VideoCarousel
        data={{ list: data }}
        img={live1}
        title={"100 ( Viral)"}
        keyType={"100vl"}
      />
    </div>
  );
};

ViralComponent.propTypes = {
  page: PropTypes.number,
};

export default ViralComponent;
