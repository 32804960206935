import VideoCarousel from "../../../components/ui/VideoCarousel";
import live1 from "../../../assets/images/svg/RadarPage/live1.svg";
import useGetTrending from "../../../hooks/useGetTrending";
import PropTypes from "prop-types";
import React from "react";

const TrendingNowComponent = ({ page }) => {
  const data = useGetTrending({ page: page, limit: 10 });

  if (!data || data.length === 0) {
    return null;
  }

  return (
    data?.length && (
      <VideoCarousel
        data={{ list: data }}
        img={live1}
        title={"Trending Now"}
        keyType={"tnl"}
      />
    )
  );
};

TrendingNowComponent.propTypes = { page: PropTypes.number };

export default TrendingNowComponent;
