import verified from "../../../assets/images/svg/RadarPage/verified.svg";
import profileImage from "../../../assets/images/svg/RadarPage/profileImg.svg";
import classes from "../VideoCarousel.module.css";
import Options from "./Options";
import { momentFromNow } from "../../../helpers/functions";
import ActiveInactivePill from "./activeInactivePill";
import { playIcon } from "../../../utils/images";
import { useNavigate } from "react-router-dom";
import React from "react";
import PropTypes from "prop-types";
import Button from "../Buttons";

const VideoCard = ({
  videoData,
  handleVidDelete,
  videoQuery,
  videoQueryVariables,
  videoQueryType,
}) => {
  const navigate = useNavigate();
  return (
    <>
      {videoData?.map((item, index) => {
        return (
          <div
            className="row m-2"
            style={{
              borderRadius: "21px",
              border: "1px solid #707070",
              background: "#121215",
              maxWidth: "31%",
              flexDirection: "column",
              margin: "1%",
            }}
            key={"daVid" + item.videoInfo?.createdAt || item.videoInfo?._id}
          >
            {/* thumbnail */}
            <div
              className="col p-0 m-0"
              style={{ position: "relative", display: "inline-block" }}
            >
              <img
                className="img-responsive w-100 h-100"
                style={{
                  objectFit: "cover",
                  borderTopLeftRadius: "20px",
                  borderTopRightRadius: "20px",
                }}
                src={
                  item?.videoInfo?.thumbnail ||
                  item?.watchLaterInfo?.videoThumbnail
                }
                alt=""
              />
              <Button
                className={"blank"}
                label={
                  <img
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: "15%",
                      cursor: "pointer",
                    }}
                    src={playIcon}
                    alt=""
                  />
                }
                handleClick={() => {
                  // if (!title.includes('live streaming'))
                  navigate("/video-player", {
                    state: {
                      videoData: item.videoInfo,
                      creatorData: item.creatorInfo,
                      videoQuery: videoQuery,
                      videoQueryVariables: videoQueryVariables,
                      videoQueryType: videoQueryType,
                    },
                  });
                }}
              />

              <ActiveInactivePill />
            </div>

            {/* text content */}
            <div className="p-0 m-0">
              <div
                style={{
                  padding: "4%",
                  width: "100%",
                  backgroundColor: "#121215",
                  borderBottomLeftRadius: "22px",
                  borderBottomRightRadius: "22px",
                  display: "grid",
                  gridTemplateRows: "2.5rem auto auto auto",
                  gap: ".5rem",
                }}
              >
                <p
                  className={`m-0 p-0 card-text ${classes.ellipsis} ${classes.cardText}`}
                >
                  {item?.videoInfo?.name ||
                    item?.watchLaterInfo?.videoName ||
                    "Demo Video Titel of video card lorem ipsum something something deuglo"}
                </p>
                <div
                  className="d-flex align-items-center"
                  style={{
                    color: "#707070",
                    gap: "3%",
                  }}
                >
                  <div className={classes.circle}></div>
                  <p className={`m-0 p-0 ${classes.watching}`}>
                    {item?.videoInfo?.viewsCount
                      ? item?.videoInfo?.viewsCount + " views"
                      : "1.5M Watching"}
                  </p>
                  <div className={classes.circle}></div>
                  <p className={`m-0 p-0 ${classes.watching}`}>
                    {item?.videoInfo?.createdAt
                      ? momentFromNow(item?.videoInfo?.createdAt)
                      : "3 months ago"}
                  </p>
                </div>

                <div className="col d-flex">
                  <div className="col-10 d-flex align-items-center gap-1">
                    <img
                      style={{
                        width: "2.222vw",
                        height: "2.222vw",
                        borderRadius: "50%",
                      }}
                      src={item.creatorInfo?.profilePicture || profileImage}
                      alt=""
                    />
                    <span
                      className={`${classes.usernameText}`}
                      style={{
                        color: "#6C6E72",
                        fontFamily: "Arial",
                        fontWeight: "400",
                      }}
                    >
                      {item.creatorInfo?.name ||
                        item.creatorInfo?.username ||
                        "John Doe"}
                    </span>
                    <img src={verified} height={"15vw"} width={"15vh"} alt="" />
                  </div>
                  <Options
                    handleVidDelete={() => {
                      handleVidDelete(
                        item?.videoInfo?._id || item?.watchLaterInfo?._id
                      );
                    }}
                    videoData={item}
                  />
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

VideoCard.propTypes = {
  videoData: PropTypes.array,
  handleVidDelete: PropTypes.func,
  videoQuery: PropTypes.object,
  videoQueryVariables: PropTypes.object,
  videoQueryType: PropTypes.string,
};

export default VideoCard;
