import React, { useEffect, useState } from "react";
import Button from "../ui/Buttons";
import google from "../../assets/images/svg/google.svg";
import x from "../../assets/images/svg/x.svg";
import facebook from "../../assets/images/svg/facebook.svg";
import eye from "../../assets/images/svg/inputFields/eye.svg";
import classes from "./Form.module.css";
import AuthClasses from "../auth/AuthCard.module.css";
import {
  emailLogin,
  emailSignup,
} from "../../services/requests/httpRequest/serverApi";
import { useNavigate } from "react-router-dom";
import { setAccessToken } from "../../store/redux/token/actions";
import { setRole } from "../../store/redux/application/actions";
import { updateUserProfile } from "../../store/redux/user/actions";
import { useDispatch } from "react-redux";
import {
  checkEmail,
  comparePassword,
  handleName,
  handlePassword,
  signUpValidation,
} from "../../helpers/functions";
import CustomToast from "../../utils/CustomToast";
import PropTypes from "prop-types";
const baseUrl = process.env.REACT_APP_API_BASE_URL;

const SignIn = ({ action, role, setForgotPassword, handleAction }) => {
  const [signIn, setSignIn] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [nameError, setNameError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  async function handleClickFn() {
    if (signIn) {
      if (email === "") {
        setEmailError("Email is required");
        return;
      }
      if (password === "") {
        setPasswordError("Password is required");
        return;
      }
      setIsLoading(true);
      const upperCaseRole = role.toUpperCase();
      const finalRole = upperCaseRole === "LISTENER" ? "USER" : upperCaseRole;
      const payload = {
        email,
        password,
        // role: role.toUpperCase() === "LISTENER" ? "USER" : role.toUpperCase(),
        role: finalRole,
        type: "normal",
      };
      try {
        await emailLogin(payload).then((response) => {
          // console.log(response);
          setIsLoading(false);
          if (response?.status === 200) {
            // dispatch(setCircleSSO({ circleSSO: response.data.circleSSO }));
            dispatch(
              setAccessToken({ access_token: response.data.access_token })
            );
            dispatch(setRole(response.data.role));
            dispatch(updateUserProfile({ data: response.data }));
            if (response.data.userProfileExists === false) {
              return navigate("/main-profile", { replace: true });
            } else {
              return navigate("/radar", { replace: true });
            }
          } else if (response?.status === 404) {
            setIsLoading(false);
            if (response.status === 404) {
              setEmailError("Email not found");
            }
          }
        });
      } catch (e) {
        if (e?.response?.status === 404) {
          setIsLoading(false);
          setEmailError("Email not found");
        } else if (e?.response?.status === 400) {
          setIsLoading(false);
          setPasswordError("Incorrect password");
        } else if (e?.response?.status === 500) {
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      }
    } else {
      if (name.length < 3) {
        setIsLoading(false);
        setNameError("Name should be between 3 to 30 characters");
        return;
      }
      if (passwordError.length) {
        CustomToast(passwordError, "warning");
        return;
      }
      const errors = signUpValidation(name, email, password, confirmPassword);
      if (errors.length) {
        errors.forEach((error) => {
          if (error.name) {
            setNameError(error.name);
          }
          if (error.email) {
            setEmailError(error.email);
          }
          if (error.password) {
            setPasswordError(error.password);
          }
          if (error.confirmPassword) {
            setConfirmPasswordError(error.confirmPassword);
          }
        });
        return;
      }
      setIsLoading(true);
      const roleUpper = role.toUpperCase();
      const finalRole = roleUpper === "LISTENER" ? "USER" : roleUpper;
      const payload = {
        name,
        email,
        password,
        // role: role.toUpperCase() === "LISTENER" ? "USER" : role.toUpperCase(),
        role: finalRole,
        type: "normal",
      };
      try {
        await emailSignup(payload).then(async (response) => {
          dispatch(
            setAccessToken({ access_token: response.data.access_token })
          );
          dispatch(setRole(response.data.role));
          dispatch(updateUserProfile({ data: response.data }));
          return navigate("/main-profile", { replace: true });
        });
      } catch (e) {
        console.log(e);
      }
    }
  }

  // const GetProfile = async () => {
  //     console.log('GetProfile');
  //     const profileData = await useGetUserPorfile();
  //     console.log('profileData :: ', profileData);
  // }

  useEffect(() => {
    // console.log('action :: ', action);
    if (action === "signin") {
      setSignIn(true);
    } else if (action === "signup") {
      setSignIn(false);
    }
  }, [action]);

  const signInLabel = (
    <>
      Sign in
      {isLoading && (
        <>
          &nbsp; <i className="fa fa-spinner fa-spin"></i>
        </>
      )}
    </>
  );

  const signUpLabel = (
    <>
      Sign up
      {isLoading && (
        <>
          &nbsp; <i className="fa fa-spinner fa-spin"></i>
        </>
      )}
    </>
  );

  // clear the input fields on switch
  useEffect(() => {
    setName("");
    setEmail("");
    setPassword("");
    setConfirmPassword("");

    if (signIn) {
      handleAction("signin");
    } else {
      handleAction("signup");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signIn]);

  useEffect(() => {
    if (password.length >= 16) {
      setPassword(password.slice(0, 15));
    }

    if (confirmPassword.length >= 16) {
      setConfirmPassword(confirmPassword.slice(0, 15));
    }

    if (!comparePassword(password, confirmPassword)) {
      setConfirmPasswordError("Password do not match");
    } else {
      setConfirmPasswordError("");
    }
  }, [password, confirmPassword]);

  const handlePasswordChange = (value) => {
    function hasNumber(string) {
      return /\d/.test(string);
    }
    function hasAlphabet(myString) {
      return /[a-zA-Z]/.test(myString);
    }

    if (value.length < 6) {
      setPasswordError("Password should be at least 6 characters");
    } else if (!hasNumber(value)) {
      setPasswordError("Password should include a number");
      return;
    } else if (!hasAlphabet(value)) {
      setPasswordError("Password should include a letter");
      return;
    } else {
      setPasswordError("");
    }
  };

  return (
    <form>
      <h1 className={AuthClasses.cardHeading} style={{ paddingBottom: "2rem" }}>
        {signIn ? "Sign in" : "Sign up"}
      </h1>
      {!signIn && (
        <>
          <input
            className={classes.inputBox}
            type="text"
            id="name"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(handleName(e.target.value))}
            onKeyDown={(e) =>
              setNameError("") ||
              setNameError(
                e.target.value.length < 3
                  ? "Name should be between 3 to 30 characters"
                  : ""
              )
            }
            onKeyUp={(e) =>
              setNameError("") ||
              setNameError(
                e.target.value.length < 3
                  ? "Name should be between 3 to 30 characters"
                  : ""
              )
            }
            min={3}
            max={30}
            required={true}
          />
          {nameError.length > 0 && (
            <span className={classes.inputErrorMessage}>{nameError}</span>
          )}
        </>
      )}
      <input
        className={classes.inputBox}
        type="email"
        id="email"
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        onKeyDown={(e) => setEmailError("")}
      />
      {checkEmail(email) ? null : (
        <span className={classes.inputErrorMessage}>
          Please enter a valid email
        </span>
      )}
      {emailError.length > 0 && (
        <span className={classes.inputErrorMessage}>{emailError}</span>
      )}
      <div style={{ position: "relative" }}>
        <input
          className={classes.inputBox}
          type={showPassword ? "text" : "password"}
          id="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(handlePassword(e.target.value))}
          onKeyDown={(e) => handlePasswordChange(e.target.value)}
          onKeyUp={(e) => handlePasswordChange(e.target.value)}
        />
        <Button
          className={"blank p-0"}
          label={
            <img
              src={eye}
              className={
                classes.passwordIcon + " " + classes.passwordIconSignin
              }
              alt="show hide password"
            />
          }
          handleClick={(e) => {
            e.preventDefault();
          }}
          handleMouseEnter={() => setShowPassword(true)}
          handleMouseLeave={() => setShowPassword(false)}
        />
      </div>
      {passwordError.length > 0 && (
        <span className={classes.inputErrorMessage}>{passwordError}</span>
      )}

      {!signIn && (
        <>
          <div style={{ position: "relative" }}>
            <input
              className={classes.inputBox}
              type={showConfirmPassword ? "text" : "password"}
              id="confirmPassword"
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={(e) =>
                setConfirmPassword(handlePassword(e.target.value))
              }
              onKeyDown={(e) =>
                setConfirmPasswordError("") ||
                comparePassword(password, confirmPassword)
                  ? setConfirmPasswordError("")
                  : setConfirmPasswordError("Passwords do not match")
              }
              onKeyUp={(e) =>
                setConfirmPasswordError("") ||
                comparePassword(password, confirmPassword)
                  ? setConfirmPasswordError("")
                  : setConfirmPasswordError("Passwords do not match")
              }
            />
            <Button
              className={"blank"}
              label={
                <img
                  src={eye}
                  className={classes.passwordIcon}
                  alt="show hide password"
                />
              }
              handleClick={(e) => {
                e.preventDefault();
              }}
              handleMouseEnter={() => setShowConfirmPassword(true)}
              handleMouseLeave={() => setShowConfirmPassword(false)}
            />
          </div>
          {confirmPasswordError.length > 0 && (
            <span className={classes.inputErrorMessage}>
              {confirmPasswordError}
            </span>
          )}
        </>
      )}
      {/* {signIn && ( */}
      <div style={{ textAlign: "center" }}>
        <Button
          className={"blank"}
          handleClick={setForgotPassword}
          label={
            <span
              style={{
                textDecoration: "none !important",
                textTransform: "none",
                color: "#F74037",
                cursor: "pointer",
              }}
              // onClick={setForgotPassword}
            >
              {signIn ? "Forgot Password?" : ""}
            </span>
          }
        />
      </div>
      {/* )} */}
      <Button
        label={!signIn ? signUpLabel : signInLabel}
        className={"cardButton active"}
        // disable={true}
        handleClick={(e) => {
          handleClickFn();
          e.preventDefault();
        }}
      />
      <div style={{ fontSize: "12px", textAlign: "center", margin: "1rem 0" }}>
        {!signIn ? "Already user?" : "Don't have an account?"} &nbsp;
        <Button
          className={"blank"}
          handleClick={(e) => {
            e.preventDefault();
            setSignIn(() => !signIn);
          }}
          label={
            <span
              // onClick={() => {
              //   setSignIn(() => !signIn);
              // }}
              style={{
                textDecoration: "none !important",
                textTransform: "none",
                color: "#F74037",
                cursor: "pointer",
              }}
            >
              Sign {!signIn ? "in" : "up"} now!
            </span>
          }
        />
      </div>
      <div style={{ textAlign: "center" }}>
        <span style={{ fontSize: "12px" }}>Or sign in with</span>
        <a
          href={`${baseUrl}/userauth/google?role=${role.toUpperCase()}&type=${
            action ? "signin" : "signup"
          }`}
        >
          <img className={classes.socialIcon} src={google} alt="googleIcon" />
        </a>
        <a
          href={`${baseUrl}/userauth/twitter?role=${role.toUpperCase()}&type=${
            action ? "signin" : "signup"
          }`}
        >
          <img className={classes.socialIcon} src={x} alt="xIcon" />
        </a>
        <a
          href={`${baseUrl}/userauth/facebook?role=${role.toUpperCase()}&type=${
            action ? "signin" : "signup"
          }`}
        >
          <img
            className={classes.socialIcon}
            src={facebook}
            alt="facebookIcon"
          />
        </a>
      </div>
      <div
        style={{ fontSize: "12px", textAlign: "center", padding: "0.5rem 0" }}
      >
        <span>
          By signing up you confirm that you accept the
          <Button
            className={"blank p-0"}
            label={
              <span
                style={{
                  fontWeight: "bold",
                  margin: "0 2px",
                  color: "#fff",
                  textTransform: "none",
                }}
              >
                terms and conditions
              </span>
            }
            handleClick={(e) => {
              e.preventDefault();
            }}
          />
          <br />
          <span>of the altRadar</span>
          <br />
          <Button
            className={"blank p-0"}
            label={
              <span style={{ fontWeight: "bold", margin: "0 2px", color: "#fff", textTransform: "none" }}>
                End User License Agreement
              </span>
            }
            handleClick={(e) => {
              e.preventDefault();
            }}
          />
          <span>(EULA).</span>
        </span>
      </div>
    </form>
  );
};

SignIn.propTypes = {
  action: PropTypes.string,
  role: PropTypes.string,
  setForgotPassword: PropTypes.func,
  handleAction: PropTypes.func,
};

export default SignIn;
