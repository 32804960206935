import useGetWelcomeGenres from "../../../hooks/useGetWelcomeGenres";
import Genre from "../Genres";
import React from "react";

const TopGenre = () => {
  const response = useGetWelcomeGenres();
  return (
    <>
      {response.map((genre, index) => (
        <Genre genre={genre.name} key={"resGen" + genre.genre} />
      ))}
    </>
  );
};

export default TopGenre;
