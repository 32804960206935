import Button from "../ui/Buttons";
import { setClearToken } from "../../store/redux/token/actions";
import {
  clearApplication,
  toggleSidebar,
  setClearApplication,
} from "../../store/redux/application/actions";
import {
  useGetToggleSidebar,
  useGetRole,
} from "../../store/redux/application/hooks";
import {
  useGetUsername,
  useGetname,
  useGetUserProfilePicture,
  useGetUserSearchHistory,
} from "../../store/redux/user/hooks";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import classes from "./TopNavigation.module.css";
import settingIcon from "../../assets/images/svg/Navbar/Setting.svg";
import swapIcon from "../../assets/images/svg/Navbar/Swap.svg";
import logoutIcon from "../../assets/images/svg/Navbar/Logout.svg";
import SidealtRadar from "../../assets/images/svg/Sidebar/SidealtRadar.svg";
import SideDash from "../../assets/images/svg/Sidebar/SideDash.svg";
import defaultProfile from "../../assets/images/png/defaultIcon.png";
import { setClearUser } from "../../store/redux/user/actions";
import useGetUserSearchSuggestions from "../../hooks/useGetUserSearchSuggestions";
import React, { useEffect, useRef, useState } from "react";
import SearchHistoryDropDown from "../searchHistoryDropDown";
import useQueryGraphql from "../../hooks/useQueryGraphql";
import { getSearchSuggestionsList } from "../../constants/graphQlQueries";

const TopNavigation = () => {
  useGetUserSearchSuggestions();
  const userSearchHistory = useGetUserSearchHistory();
  const searchBarRef = useRef(null);
  const searchListRef = useRef(null);
  const [focused, setFocused] = useState(false);
  const [focusedList, setFocusedList] = useState(false);
  const Navigate = useNavigate();
  const Location = useLocation();
  const role = useGetRole();
  const username = useGetUsername();
  const profilePicture = useGetUserProfilePicture();
  const name = useGetname();
  const [searchValue, setSearchValue] = useState("");
  const [searchSuggestionList, setSearchSuggestionList] = useState([]);
  const dispatch = useDispatch();

  const logout = () => {
    dispatch(setClearToken());
    dispatch(clearApplication());
    dispatch(setClearUser());
    dispatch(setClearApplication());
  };
  const showSidebar = useGetToggleSidebar();
  const handleSidebar = () => {
    dispatch(toggleSidebar());
  };

  const handleSearch = (event, type) => {
    const query = event.target.value;
    const currentParams = new URLSearchParams(Location.search); // Ensure currentParams is defined correctly

    if (Location?.pathname === "/gcp-youtube-poc") {
      // For history click
      if (type === "history") {
        currentParams.set("search", query); // Set or update the search query parameter
        Navigate(`/gcp-youtube-poc?${currentParams.toString()}`); // Navigate to the path with updated params
        setFocusedList(false);
        setFocused(false);
      }

      // For pressing Enter key
      if (event.key === "Enter") {
        event.preventDefault(); // Prevent default form submission behavior
        currentParams.set("search", query); // Set or update the search query parameter
        Navigate(`/gcp-youtube-poc?${currentParams.toString()}`);
      }
    } else {
      // Handling for other paths
      if (type === "history") {
        Navigate(`/search?search=${encodeURIComponent(query)}`);
        setFocusedList(false);
        setFocused(false);
      }

      if (event.key === "Enter") {
        event.preventDefault(); // Prevent default form submission
        Navigate(`/search?search=${encodeURIComponent(query)}`);
      }
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchBarRef.current &&
        !searchBarRef.current.contains(event.target) &&
        searchListRef.current &&
        !searchListRef.current.contains(event.target)
      ) {
        setFocused(false);
        setFocusedList(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (focused) {
      searchBarRef.current.focus();
    } else {
      searchBarRef.current.blur();
    }
  }, [focused]);

  const unFocus = () => {
    if (focused && !focusedList) setFocused(false);
  };

  const focus = () => {
    if (!focused) setFocused(true);
  };

  const handleSearchInput = (e) => {
    const { value } = e.target;
    setSearchValue(value);
  };

  const [queryVariables, setQueryVariables] = useState({
    searchKeyword: "",
    page: 1,
    limit: 10,
  });

  useEffect(() => {
    if (searchValue.length > 2) {
      const delayDebounceFn = setTimeout(() => {
        setQueryVariables({ searchKeyword: searchValue, page: 1, limit: 10 });
      }, 1000);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [searchValue]);

  const { data } = useQueryGraphql(getSearchSuggestionsList, queryVariables);

  useEffect(() => {
    if (data) {
      const formattedData = data["getSearchSuggestionsList"]?.list.map(
        (item) => item.searchKeyword
      );
      setSearchSuggestionList(formattedData);
    }
  }, [data]);

  return (
    <div
      className="container-fluid m-0 p-0 d-flex justify-content-between"
      style={{
        position: "fixed",
        top: 0,
        width: "100%",
        zIndex: 1000,
        background: "#000",
      }}
    >
      <div
        className="col-sm-3 col-md-3 col-lg-2 d-flex"
        style={{
          background: showSidebar ? "#18181C" : "",
          padding: "5px 1rem 1rem 1rem",
          gap: "2rem",
        }}
      >
        <Button
          className={"blank p-0"}
          label={
            <img
              src={SideDash}
              alt="sidebar"
              style={{ cursor: "pointer", width: "1.5rem" }}
            />
          }
          handleClick={handleSidebar}
        />
        <img
          className={`d-none d-sm-block`}
          src={SidealtRadar}
          alt="logo"
          style={{ width: "50%" }}
        />
      </div>

      <div className="col-4 d-none d-sm-block py-3 me-6">
        <form action="">
          <input
            type="text"
            className={classes.searchInputField}
            ref={searchBarRef}
            placeholder={
              Location?.pathname === "/gcp-youtube-poc"
                ? `Search for youtube videos`
                : `Search for albums, songs, singers, genres`
            }
            value={searchValue}
            onChange={(e) => {
              handleSearchInput(e);
            }}
            onKeyDown={handleSearch}
            // onBlur={unFocus}
            onFocus={focus}
          />
        </form>
        {focused && (
          <SearchHistoryDropDown
            HistoryArray={
              searchValue.length > 2 ? searchSuggestionList : userSearchHistory
            }
            handleSearch={handleSearch}
            ref_={searchListRef}
            setFocusedList={setFocusedList}
            showRecentImage={!searchValue?.length}
          />
        )}
      </div>

      <div className="col-md-4 d-flex justify-content-end py-3 px-2 pe-2">
        <div className="d-flex">
          <Button
            className={"circle message"}
            handleClick={() => Navigate("/messages")}
          />
          {role !== "USER" && (
            <Button
              className={"circle uploadVideo"}
              handleClick={() => Navigate("/upload")}
            />
          )}
          <Button
            className={"circle notification"}
            handleClick={() => Navigate("/notifications")}
          />
          <div className="dropdown">
            <Button
              className={"dropdown-toggle circle profile"}
              toggle={"dropdown"}
              backImg={`url(${profilePicture || defaultProfile})`}
            />
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <div
                className="dropdown-item px-0"
                style={{
                  height: "4rem",
                  borderBottom: "1px solid grey",
                  borderWidth: "thin",
                  alignItems: "center",
                }}
              >
                <Button
                  className={"blank p-0 fullWidth"}
                  handleClick={() => Navigate("/profile")}
                  label={
                    <div className="d-flex w-100 text-capitalize px-0">
                      <img
                        style={{
                          width: "2rem",
                          height: "2rem",
                          borderRadius: "50%",
                          margin: "0.4rem",
                        }}
                        src={profilePicture || defaultProfile}
                        alt=""
                      />
                      <div className={classes.usernameDiv}>
                        <span className="m-0  p-0">{username || name}</span>
                        <span className="m-0  p-0">Free</span>
                      </div>
                    </div>
                  }
                />
              </div>
              <div className="pt-2">
                <Button
                  className={"blank fullWidth p-0"}
                  label={
                    <span className="dropdown-item px-2 py-1 d-flex text-capitalize">
                      <img
                        src={settingIcon}
                        alt="settings"
                        style={{ marginRight: "8px" }}
                      />
                      <span>Settings</span>
                    </span>
                  }
                  handleClick={() => Navigate("/settings")}
                />
                <Button
                  className={"blank fullWidth p-0"}
                  label={
                    <span className="dropdown-item px-2 py-1 d-flex text-capitalize">
                      <img
                        src={swapIcon}
                        alt="swap profile"
                        style={{ marginRight: "8px" }}
                      />
                      <span>Profile Switch</span>
                    </span>
                  }
                />
                <Button
                  className={"blank fullWidth p-0"}
                  label={
                    <span className="dropdown-item px-2 pt-1 pb-0 d-flex text-capitalize">
                      <img
                        src={logoutIcon}
                        alt="logout"
                        style={{ marginRight: "8px" }}
                      />
                      <span>Logout</span>
                    </span>
                  }
                  handleClick={logout}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopNavigation;
