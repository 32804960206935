import axios from 'axios';
import { setClearToken } from '../../store/redux/token/actions';
import { store } from '../../store/redux';
import CustomToast from '../../utils/CustomToast';
import { getBaseUrl } from '../../config/config';

export const ERROR_MESSAGES = {
    SERVER_DOWN: 'Server Down!',
    NO_INTERNET: 'No internet connection!',
};

const createHeaders = (jwt, body) => ({
    ...(jwt && { Authorization: `Bearer ${jwt}` }),
    ...(body instanceof FormData ? { 'content-type': 'multipart/form-data' } : { 'Content-Type': 'application/json' })
});

const handleApiError = (error) => {
    const { response } = error;
    if (response) {
        switch (response.status) {
            case 400:
                CustomToast(response.data.message);
                break;
            case 401:
                store.dispatch(setClearToken());
                window.location.reload();
                break;
            case 404:
                CustomToast(response.data.message);
                break;
            default:
                CustomToast(
                    window.navigator.onLine
                        ? ERROR_MESSAGES.SERVER_DOWN
                        : ERROR_MESSAGES.NO_INTERNET,
                    'error'
                );
        }
    } else {
        CustomToast(
            window.navigator.onLine
                ? ERROR_MESSAGES.SERVER_DOWN
                : ERROR_MESSAGES.NO_INTERNET,
            'error'
        );
    }
    throw error; // rethrow the error to maintain error propagation
}

const ApiCaller = async (
    endpoint,
    method,
    body
) => {
    const baseUrl = getBaseUrl();
    const jwt = store.getState()._session.access_token;
    const headers = createHeaders(jwt, body);

    const config = {
        method: method,
        url: `${baseUrl}${endpoint}`,
        headers: headers,
        data: body
    };

    try {
        const response = await axios(config);
        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        // if (error.response || error?.response?.status === 400) {
        //     CustomToast(error.response.data.message);
        // }
        // else if (error?.response?.status === 401) {
        //     store.dispatch(setClearToken());
        //     window.location.reload();
        // }
        // else if (error?.response?.status === 404) {
        //     CustomToast(error.response.data.message);
        // }
        // else {
        //     if (window.navigator.onLine) {
        //         CustomToast('Server Down!', 'error');
        //     } else {
        //         CustomToast('No internet connection!', 'error');
        //         return false;
        //     }
        // }
        return handleApiError(error);
        // throw error; // rethrow the error to maintain error propagation
    }
};

export default ApiCaller;
