import { useGetToggleSidebar } from "../../store/redux/application/hooks";
import SidebarElements from "../../constants/sidebar";
import SidebarBottomContent from "../../constants/SidebarBottomContent";
import useScreenSize from "../../hooks/useScreenSize";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import classes from "./SideNavigation.module.css";
import React from "react";

const Sidebar = () => {
  const showSidebar = useGetToggleSidebar();
  const genreTagStyle = {
    backgroundColor: "#2B2B2B",
    color: "white",
    padding: "5px 0.5rem",
    borderRadius: "15px",
    margin: "0.5rem 0.5rem 0 0", // Add margin between genre tags
    display: "inline-block", // Display tags inline
    fontSize: "12px",
  };

  const { width } = useScreenSize();

  return (
    <div
      className={`${
        showSidebar
          ? "col-sm-3 col-md-3 col-lg-2"
          : `${classes.sideBarClosedDiv}`
      }`}
      style={{
        background: showSidebar ? "#18181C" : "#000000",
        borderTop: "1px solid #18181C",
        padding: showSidebar ? "0rem 0 0rem 1rem" : "0",
        position: width <= 576 ? "fixed" : "sticky",
        top: "89px",
        height: "calc(100vh)",
        overflowY: "auto",
        zIndex: "99",
        width: showSidebar && width <= 576 ? "50%" : "",
        transition: "all 0.5s cubic-bezier(0.22, 0.61, 0.36, 1) 0s",
      }}
    >
      {/* {showSidebar && ( */}
      <div className="sidebar" style={{ zIndex: "1000" }}>
        <ul className="nav flex-column">
          {SidebarElements.map((item) => (
            <li className="nav-item" key={item.name}>
              <NavLink
                to={item.active ? item.path : "#"}
                className={`${classes.navLinks} ${
                  showSidebar ? "justify-content-start" : classes.navLinksClose
                }`}
                style={({ isActive }) => ({
                  textDecoration: "none",
                  color: isActive ? "#FFFFFF" : "#6C6E72",
                })}
                onClick={(e) => {
                  if (!item.active) {
                    e.preventDefault();
                    toast("Coming Soon", { theme: "dark" });
                  }
                }}
              >
                {({ isActive }) => (
                  <>
                    <img
                      style={{
                        width: "1.5rem",
                        height: "1.5rem",
                        filter: isActive
                          ? "invert(100%) sepia(0%) saturate(2%) hue-rotate(354deg) brightness(200%) contrast(100%)"
                          : "",
                      }}
                      src={item.icon}
                      alt={item.name}
                    />
                    {showSidebar && (
                      <span
                        style={{ padding: ".8rem 1rem", fontSize: ".85em" }}
                      >
                        {item.name}
                      </span>
                    )}
                  </>
                )}
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
      {/*  )} */}

      {/* Tags */}
      {showSidebar && (
        <>
          <div
            style={{ borderBottom: "1px solid #535050", marginRight: "1rem" }}
          ></div>
          <div className="py-1">
            {SidebarBottomContent.map((item) => (
              <div key={item} style={genreTagStyle}>
                {item}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default Sidebar;
