import { getUserWiseSearchSuggestionsList } from '../constants/graphQlQueries';
import useQueryGraphql from './useQueryGraphql';
import { useGetUserId } from '../store/redux/user/hooks';
import { useDispatch } from 'react-redux';
import { setUserSearchHistory } from '../store/redux/user/actions';
import { useEffect } from 'react';

export const useGetUserSearchSuggestions = () => {
    const userId = useGetUserId();
    const dispatch = useDispatch();
    const { data } = useQueryGraphql(getUserWiseSearchSuggestionsList, { 'userId': userId, 'page': 1, 'limit': 10 });

    const Update = (data) => {
        if (data?.getUserWiseSearchSuggestionsList?.list) {
            const formattedData = data['getUserWiseSearchSuggestionsList']?.list.map((item) => item.searchKeyword);
            dispatch(setUserSearchHistory({ userSearchHistory: formattedData }));
        }
    }
    useEffect(() => {
        Update(data);
    }, [data])

}

export default useGetUserSearchSuggestions;
