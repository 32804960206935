const reactSelectStyles = {
    control: (base, state) => ({
        ...base,
        background: "#000",
        // match with the menu
        borderRadius: 'none',
        // Overwrittes the different states of border
        borderColor: '#fff',
        // Removes weird border around container
        // boxShadow: state.isFocused ? null : null,
        boxShadow: null,
        "&:hover": {
            // Overwrittes the different states of border
            borderColor: '#707070'
        },
        borderBottom: '1px solid #707070',
        borderTop: 'none',
        borderLeft: 'none',
        borderRight: 'none',

    }),
    menuList: styles => ({
        ...styles,
        background: '#313131',
        color: '#fff',
        borderRadius: '5px',
    }),
    option: (styles, { isFocused, isSelected }) => ({
        ...styles,
        background: isFocused
            ? '#656565'
            : isSelected
                ? '#656565'
                : undefined,
        zIndex: 1,
        height: '5%',
        fontSize: '12px',
    }),
    menu: base => ({
        ...base,
        zIndex: 100,
    }),
    indicatorsContainer: (prevStyle, state) => state.isMulti ? ({
        ...prevStyle,
        display: 'none'
    }) : null,
    input: styles => ({ ...styles, color: '#fff', padding: '0rem' }),
    placeholder: styles => ({ ...styles, background: '#000', color: '#fff' }),
    valueContainer: styles => ({ ...styles, padding: '0rem' }),
    multiValue: styles => ({ ...styles, background: '#707070', color: '#fff', }),
    multiValueLabel: styles => ({ ...styles, color: '#fff' }),
    singleValue: (provided, state) => ({
        ...provided,
        color: 'white',
    })
}

export default reactSelectStyles;