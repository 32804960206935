import React, { useEffect, useState } from "react";
import { previousSearchIcon } from "../../utils/images";
import classes from "./SearchHistoryList.module.css";
import PropTypes from "prop-types";
import Button from "../ui/Buttons";

const SearchHistoryDropDown = ({
  HistoryArray,
  handleSearch,
  ref_,
  // setFocusedList,
  showRecentImage,
}) => {

  const [list, setList] = useState([]);
  useEffect(() => {
    setList(HistoryArray);
  }, [HistoryArray]);

  const listDiv = (index, item) => {
    return (
      <Button
        className={"blank fullWidth"}
        label={
          <div
            key={item}
            className={classes.innerDiv}
            // onClick={() => {
            //   handleSearch({ target: { value: item } }, "history");
            // }}
          >
            {showRecentImage && (
              <img src={previousSearchIcon} alt="previous search" />
            )}
            <p>{item}</p>
          </div>
        }
        handleClick={() => {
          // console.log("Button clicked!");
          handleSearch({ target: { value: item } }, "history");
        }}
      />
    );
  };

  const jsx = list?.map((item, index) => {
    if (item === "") return null;
    else return listDiv(index, item);
  });

  return (
    <div
      className={classes.searchHistoryListDiv}
      ref={ref_}
      // onMouseDown={() => setFocusedList(true)}
      // onBlur={() => setFocusedList(false)}
    >
      {jsx.length > 0 ? jsx : listDiv(0, "No Suggestions Found")}
    </div>
  );
};

SearchHistoryDropDown.propTypes = {
  HistoryArray: PropTypes.array,
  handleSearch: PropTypes.func,
  ref_: PropTypes.object,
  // setFocusedList: PropTypes.func,
  showRecentImage: PropTypes.bool,
};

export default SearchHistoryDropDown;
