import "./App.css";
import React from "react";
import { ApolloProvider } from "@apollo/client";
import { Provider } from "react-redux";
import { store, persistor } from "./store/redux";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Navigation from "./Navigation";
import { client } from "./services/apolloClient";
import { PersistGate } from "redux-persist/integration/react";
import SocketIO from "./services/socketIO/SocketIO";
import PropTypes from "prop-types"; // Import propTypes

const Providers = ({ children }) => {
  return (
    <ApolloProvider client={client}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <BrowserRouter onUpdate={() => window.scrollTo(0, 0)}>
            <SocketIO />
            {children}
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </ApolloProvider>
  );
};

// Define prop types for Providers component
Providers.propTypes = {
  children: PropTypes.node,
};

function App() {
  return (
    <Providers>
      {/* <CircleWidget /> */}
      <ToastContainer />
      <Navigation />
    </Providers>
  );
}

export default App;
