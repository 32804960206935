import Modal from "react-bootstrap/Modal";
import "./modal.css";
import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

/**
 * Renders a Bootstrap modal component.
 *
 * @param {boolean} show - Determines if the modal is visible.
 * @param {boolean} showHeader - Determines if the modal header is displayed.
 * @param {function} handleClose - Function to handle the modal close event.
 * @param {function} handleSubmit - Function to handle the modal submit event.
 * @param {string} title - Title of the modal.
 * @param {ReactNode} body - Content of the modal body.
 * @param {ReactNode} footer - Content of the modal footer.
 * @param {string} className - Custom CSS class for the modal.
 * @param {string} size - Size of the modal.
 * @param {boolean} allowBackdropClose - Allows closing the modal by clicking outside it.
 */
function BootstrapModal({
  show,
  showHeader,
  handleClose,
  //   handleSubmit,
  title,
  body,
  footer,
  className,
  size,
  allowBackdropClose = false, // Default to not allowing outside clicks to close the modal
}) {
  const navigate = useNavigate();
  // Enhanced handleClose function
  const onClose = () => {
    if (allowBackdropClose) {
      navigate(-1); // Navigate to the previous page
    }
    if (handleClose) {
      handleClose();
    }
  };
  return (
    <Modal
      size={size}
      className={className}
      show={show}
      onHide={onClose}
      // backdrop="static"
      // keyboard={false}
      backdrop={allowBackdropClose ? true : "static"} // Toggle based on the prop
      keyboard={allowBackdropClose} // Allows Esc key to close the modal if enabled
      aria-labelledby="contained-modal-title-vcenter"
      centered
      data-bs-theme="dark"
    >
      {showHeader && (
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
      )}

      <Modal.Body>{body}</Modal.Body>
      {footer && <Modal.Footer>{footer}</Modal.Footer>}
    </Modal>
  );
}

BootstrapModal.propTypes = {
  show: PropTypes.bool,
  showHeader: PropTypes.bool,
  handleClose: PropTypes.func,
  // handleSubmit: PropTypes.func,
  title: PropTypes.string,
  body: PropTypes.node,
  footer: PropTypes.node,
  className: PropTypes.string,
  size: PropTypes.string,
  allowBackdropClose: PropTypes.bool,
};

export default BootstrapModal;
