import classes from "../Form.module.css";
import RadioCustom from "../RadioCustom";
import React, { useState, useRef, useEffect } from "react";
import Button from "../../ui/Buttons";
import moment from "moment";
import DatePicker from "react-datepicker";
import PropTypes from "prop-types";
import "react-datepicker/dist/react-datepicker.css";

const PageOne = ({
  form,
  setForm,
  handleNextClick,
  setTotalDays,
  totalDays,
  customDays,
  setCustomDays,
}) => {
  const [errorMsg, setErrorMsg] = useState({});
  const [custom, setCustom] = useState(false);
  const nextRef = useRef();
  const [customDate, setCustomDate] = useState(false);
  const budgetOption = [
    { label: "$5", value: 5 },
    { label: "$10", value: 10 },
    { label: "$20", value: 20 },
    { label: "$50", value: 50 },
    { label: "$100", value: 100 },
    { label: "$500", value: 500 },
    { label: "custom", value: "custom" },
  ];

  //expire date
  const expireDateOption = [
    { label: "1 day", value: 1 },
    { label: "2 day", value: 2 },
    { label: "3 day", value: 3 },
    { label: "5 day", value: 5 },
    { label: "7 day", value: 7 },
    { label: "30 day", value: 30 },
    { label: "custom", value: "custom" },
  ];

  const handleAdType = (option) => {
    setErrorMsg({ fieldName: "budget", msg: "" });

    if (option === "custom") {
      setCustom(true);
      setForm({ ...form, budget: 0 });
    } else {
      setCustom(false);
      setForm({ ...form, budget: option });
    }
  };

  const handleExpireDate = (value) => {
    const date = moment(value).format("YYYY-MM-DD");
    setForm({ ...form, endDate: date });
    const today = moment().startOf("day");
    const targetDate = moment(value).startOf("day");
    const differenceInDays = targetDate.diff(today, "days");
    setTotalDays(differenceInDays);
  };

  const handleEndDate = (value) => {
    if (value === "custom") {
      setCustomDate(true);
      setCustomDays(true);
    } else {
      setCustomDate(false);
      setCustomDays(false);
      const daysToAdd = parseInt(value, 10);
      const endDate = moment().add(daysToAdd, "days").format("YYYY-MM-DD");
      setForm({ ...form, endDate: endDate });
      setTotalDays(daysToAdd);
    }
  };

  useEffect(() => {
    if (customDays) {
      console.log("customDate", customDays);
    }
  }, [totalDays, customDays]);

  const [selectedDate, setSelectedDate] = useState(null);

  const customStyles = {
    input: {
      background: "black",
      color: "white",
      border: "none",
      borderBottom: "1px solid white",
    },
    calendar: {
      backgroundColor: "black",
      color: "white",
      border: "none",
    },
    header: {
      backgroundColor: "black",
      borderBottom: "1px solid white",
    },
    day: {
      color: "white",
    },
    selectedDay: {
      backgroundColor: "white",
      color: "black",
    },
  };

  const submitPage = () => {
    if (!form.budget) {
      setErrorMsg({ fieldName: "budget", msg: "Please select a budget" });
      return;
    }
    if (!form.endDate) {
      setErrorMsg({
        fieldName: "endDate",
        msg: "Please select how long you want to show",
      });
      return;
    }

    handleNextClick();
  };

  return (
    <div className="row gap-3 pb-4 mb-4">
      <div className="p-0">
        <label htmlFor="spend" className={classes.labelPersonalize}>
          How much do you want to spend*
        </label>
        <form action="">
          <div className="d-flex gap-3 flex-wrap">
            {budgetOption.map((option) => {
              return (
                <RadioCustom
                  key={"radio2" + option.label}
                  option={option.label}
                  onSelect={() => {
                    handleAdType(option.value);
                  }}
                  checked={option.value === form.budget}
                />
              );
            })}
            {errorMsg.fieldName === "budget" && (
              <span style={{ color: "red", fontSize: "14px" }}>
                {errorMsg.msg}
              </span>
            )}
          </div>
          {custom && (
            <>
              <label htmlFor="dailyBudget" className={classes.labelPersonalize}>
                Enter a daily budget (Dollar $)*
              </label>
              <input
                className={`${classes.inputBox}`}
                type="number"
                placeholder="Enter amount here"
                value={form.budget}
                onChange={(e) => {
                  setForm({ ...form, budget: e.target.value });
                  setErrorMsg({ fieldName: "endDate", msg: "" });
                }}
              />
            </>
          )}
        </form>
      </div>

      <div className="p-0">
        {
          <>
            <label htmlFor="adsRun" className={classes.labelPersonalize}>
              How long will your ads run*?
            </label>

            <form action=" ">
              <div className="d-flex gap-2 flex-wrap">
                {expireDateOption.map((option) => {
                  return (
                    <RadioCustom
                      key={"radio3" + option.label}
                      option={option.label}
                      className={""}
                      onSelect={() => {
                        handleEndDate(option.value);
                      }}
                      checked={
                        option.value === totalDays ||
                        (option.value === "custom" && customDays)
                      }
                    />
                  );
                })}
              </div>
              {errorMsg.fieldName === "endDate" && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {errorMsg.msg}
                </span>
              )}
            </form>
            {(customDate || form.endDate) && (
              <>
                <label htmlFor="endDate" className={classes.labelPersonalize}>
                  Enter End Date*
                </label>
                <div>
                  <DatePicker
                    selected={selectedDate || form.endDate}
                    onChange={(date) => {
                      setSelectedDate(date);
                      handleExpireDate(date);
                    }}
                    customInput={<input style={customStyles.input} />}
                    calendarClassName="custom-calendar"
                    dayClassName={(date) => "custom-day"}
                    onKeyDown={(e) => e.preventDefault()}
                    dateFormat="dd-MM-yyyy"
                  />
                </div>
              </>
            )}
          </>
        }
      </div>

      <div className={"d-flex justify-content-center px-5"} ref={nextRef}>
        <Button
          label={"Next"}
          className={"cardButton active"}
          handleClick={submitPage}
        />
      </div>
    </div>
  );
};

PageOne.propTypes = {
  form: PropTypes.object,
  setForm: PropTypes.func,
  handleNextClick: PropTypes.func,
  totalDays: PropTypes.string,
  customDays: PropTypes.string,
  setTotalDays: PropTypes.func,
  setCustomDays: PropTypes.func,
};

export default PageOne;
