import altRadarWhiteIcon from "../../assets/images/svg/altRadar-logo-white.svg";
import facebookIcon from "../../assets/images/svg/footerFacebook.svg";
import instaIcon from "../../assets/images/svg/footerInstagram.svg";
import twitterIcon from "../../assets/images/svg/footerTwitter.svg";
import appleStore from "../../assets/images/svg/appleStoreFull.svg";
import playStore from "../../assets/images/svg/playStoreFull.svg";
import mobiles from "../../assets/images/png/footerMobile.png";
import classes from "./footer.module.css";
import React from "react";

const Footer = () => {
  const socialIcons = [
    { src: facebookIcon, alt: "facebook icon" },
    { src: twitterIcon, alt: "twitter icon", className: "mx-3" },
    { src: instaIcon, alt: "instagram icon", className: "mx-1" },
  ];

  const aboutLinks = [
    "About us",
    "Investors",
    "Press Release",
    "Privacy Policy",
    "Terms of Usage",
    "Legal",
  ];

  const artistLinks = [
    "What's there for me",
    "Tips & Resources",
    "Jobs",
    "Premium Plans",
    "FAQs",
    "Contact us",
  ];

  const listenerLinks = [
    "What's there for me",
    "Tips & Resources",
    "Premium Plans",
    "Albums",
    "Live",
  ];

  const footerSections = [
    { title: "About", links: aboutLinks },
    { title: "For Artists", links: artistLinks },
    { title: "For Listeners", links: listenerLinks },
  ];

  return (
    <>
      <div className={classes.bottomSection}>
        <div className="container">
          <div className="row">
            <div
              className={`col-lg-4 col-md-4 col-sm-4 align-self-center ${classes.bottom}`}
            >
              <h3 className={classes.bottomSectionHeading}>
                Always stay connected
              </h3>
              <h2 className={classes.bottomSectionHeading1}>
                Music on the go!
              </h2>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6">
              <img src={mobiles} alt="" className={classes.phoneImage} />
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12">
              <div className={classes.appsLogo1}>
                <img
                  src={appleStore}
                  alt="white-apple"
                  className={classes.appStore}
                />
                <img
                  src={playStore}
                  alt="White Play store"
                  className={classes.playStore}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={classes.footerSection}>
        <div className="container-fluid">
          <div className="row">
            <div
              className={`${classes.logoSection} col-lg-3 col-md-3 col-sm-3 col-xs-3`}
            >
              <div>
                <div className={classes.logoContainer}>
                  <a href="/" target="_self">
                    <img src={altRadarWhiteIcon} alt="altRadar Logo" />
                  </a>
                </div>
                <div className={classes.footerHeading4}>Get social with us</div>
                <div className={classes.socialIcons}>
                  {socialIcons.map((icon) => (
                    <img
                      key={icon.src}
                      src={icon.src}
                      alt={icon.alt}
                      className={icon.className || ""}
                    />
                  ))}
                </div>
              </div>
            </div>

            {footerSections.map((section) => (
              <div
                key={section.title}
                className={`col-lg-3 col-md-3 col-sm-3 col-xs-3 ${
                  classes[section.title.replace(/\s+/g, "").toLowerCase()]
                }`}
              >
                <h1 className={classes.aboutHeading}>{section.title}</h1>
                {section.links.map((link) => (
                  <p key={link} className={classes.aboutParagraph}>
                    {link}
                  </p>
                ))}
              </div>
            ))}
          </div>
          <h3 className={classes.footerBottomHeading}>
            Copyright altRadar &copy; 2021 | All Rights Reserved
          </h3>
        </div>
      </div>
    </>
  );
};

export default Footer;
