import classes from "./Form.module.css";
import PropTypes from "prop-types";
import React from "react";

const RadioCustom = ({ option, onSelect, checked, className, disable }) => {
  return (
    <label className={`${classes.container} ${classes?.[className]}`}>
      <input
        type="radio"
        checked={checked}
        name={"radio"}
        onChange={onSelect}
        disabled={disable}
      />
      <span className={classes.checkmark}></span>
      {option}
    </label>
  );
};

RadioCustom.propTypes = {
  option: PropTypes.string,
  onSelect: PropTypes.func,
  checked: PropTypes.bool,
  className: PropTypes.string,
  disable: PropTypes.bool,
};

export default RadioCustom;
