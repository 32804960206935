import axios from 'axios';
import { setClearToken } from '../../store/redux/token/actions';
import { store } from '../../store/redux';
import { toast } from "react-toastify";
import { getBaseUrl } from '../../config/config';
import { ERROR_MESSAGES } from "./index";
import CustomToast from '../../utils/CustomToast';

const createHeaders = (jwt, body) => ({
    ...(jwt && { Authorization: `Bearer ${jwt}` }),
    ...(body instanceof FormData ? { 'content-type': 'multipart/form-data' } : { 'Content-Type': 'application/json' })
});

const handleApiError = (error) => {
    if (axios.isCancel(error)) {
        console.log('Request canceled', error.message);
        return;
    }
    const { response } = error;
    if (response.data.status === 400) return response;
    const message = window.navigator.onLine
        ? ERROR_MESSAGES.SERVER_DOWN
        : ERROR_MESSAGES.NO_INTERNET;
    if (response) {
        switch (response.status) {
            case 400:
                return response;
            case 401:
                store.dispatch(setClearToken());
                window.location.reload();
                break;
            case 404:
                toast(response.data.message, { theme: "dark" });
                break;
            default:
                CustomToast(message, 'error');
        }
    }
    throw error; // Maintain error propagation
};

const formApiCaller = async (endpoint, method, body, cancelToken) => {
    const baseUrl = getBaseUrl();
    const jwt = store.getState()._session.access_token;
    const headers = createHeaders(jwt, body);

    const config = {
        method: method,
        url: `${baseUrl}${endpoint}`,
        headers: headers,
        data: body instanceof FormData ? body : JSON.stringify(body), // If body is FormData, leave it as is, else stringify it
        cancelToken: cancelToken // Pass the cancel token to the request config
    };

    try {
        const response = await axios(config);
        if (response)
            return response.data;
    } catch (error) {
        // if (axios.isCancel(error)) {
        //     console.log('Request canceled', error.message);
        // } else {
        //     console.log('request response ---- ', error.response);
        //     if (error?.response?.status === 400) {
        //         return error.response;
        //     } else if (error?.response?.data?.status === 400) {
        //         return error.response;
        //     } else if (error?.response?.status === 401) {
        //         store.dispatch(setClearToken());
        //         window.location.reload();
        //     } else if (error?.response?.status === 404) {
        //         toast(error.response.data.message, { theme: "dark" });
        //     } else {
        //         if (window.navigator.onLine) {
        //             toast('Server Down!1', { theme: "dark" });
        //         } else {
        //             toast('No internet connection!', { theme: "dark" });
        //         }
        //     }
        //     throw error; // rethrow the error to maintain error propagation
        // }
        return handleApiError(error);
    }
};

export const createCancelToken = () => {
    const source = axios.CancelToken.source();
    return source;
};

export default formApiCaller;
