import classes from "../AdDemographics.module.css";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  ArcElement,
  LineElement,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import React from "react";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels // Register the plugin
);

const ClicksGenre = () => {
  const serialLabels = ["1", "2", "3", "4", "5", "6"];
  const countryNames = [
    "Rock",
    "Jazz",
    "Pop",
    "Disco",
    "Hardcore",
    "Punk rock",
  ];

  const progressBarData = {
    labels: serialLabels,
    datasets: [
      {
        data: [25.5, 50.5, 75.5, 20.5, 5.5, 30.5],
        backgroundColor: ["#2196F3"],
        borderRadius: 10,
        borderSkipped: false,
        fill: false,
        maxBarThickness: 12,
      },
    ],
  };

  const progressBarOptions = {
    indexAxis: "y", // Make the bars horizontal
    plugins: {
      legend: {
        display: false, // Hide the legend
      },
      tooltip: {
        enabled: false, // Disable the tooltip
      },
      datalabels: {
        display: true,
        color: "#41b2d6",
        font: {
          weight: "bold",
        },
        formatter: (value, context) => {
          return `${countryNames[context.dataIndex]}`;
        },
        anchor: "top",
        align: "top",
      },
    },
    scales: {
      y: {
        ticks: {
          callback: function (value, index) {
            return serialLabels[index];
          },
          font: {
            weight: "bold",
          },
          color: "#fff",
        },
      },
      x: {
        ticks: {
          display: false,
        },
      },
    },
    responsive: true,
  };
  return (
    <div className={classes.NoOfViews}>
      <div className={`${classes.noViews2} justify-content-center`}>
        <span className={classes.viewText}>Clicks from genre</span>
      </div>
      <div style={{ position: "relative" }}>
        <Bar
          className={classes.width80}
          data={progressBarData}
          options={progressBarOptions}
        />
        <div
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            padding: "0 10px",
          }}
        >
          {progressBarData.datasets[0].data.map((value) => (
            <div
              key={"p2qws" + value}
              style={{ fontWeight: "bold", color: "#41b2d6" }}
            >
              {value}%
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ClicksGenre;
