import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../components/ui/Buttons";
import AuthCard from "../../components/auth/AuthCard";
import classes from "./signInAs.module.css";
import altLogoWhite from "../../assets/images/svg/altRadar-logo-white.svg";
const SignInAs = () => {
  const Navigate = useNavigate();
  const { state } = useLocation();

  //   const [key, setKey] = useState("signin");
  const [type, setType] = useState("artist");

  //   useEffect(() => {
  //     if (state.key) {
  //       setKey(state.key);
  //     } else {
  //       setKey("signin");
  //     }
  //   }, [state.key]);

  return (
    <div className="container-fluid">
      <div className={`${classes.signinas} row`}>
        <div className="col">
          <div className="row">
            <div className="col">
              <Button
                className={"blank p-0"}
                label={
                  <img
                    src={altLogoWhite}
                    alt="altRadar logo"
                    className={classes.logo}
                  />
                }
                handleClick={() => Navigate(-1)}
              />
            </div>
          </div>
          <div
            className="row"
            style={{
              height: "74.2%",
              top: "23.2vh",
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <div className="col-lg-5" style={{ padding: "6rem 0" }}>
              <div className="row" style={{ padding: "0 0 2rem" }}>
                <h1 className={classes.heading}>
                  Which profile do you want to
                </h1>
                <h1 className={classes.heading}>Sign in / Sign up ?</h1>
              </div>
              <div className="row">
                <div className="col-6">
                  <Button
                    label="artist"
                    handleClick={() => setType("artist")}
                    className={`signinas ${
                      type === "artist" ? "active" : "inActive"
                    }`}
                  />
                </div>
                <div className="col-6">
                  <Button
                    label="listener"
                    handleClick={() => setType("listener")}
                    className={`signinas ${
                      type !== "artist" ? "active" : "inActive"
                    }`}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <AuthCard type={type} action={state.action} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignInAs;
