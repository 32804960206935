import classes from "./AdManager.module.css";
import PageThreeForm from "../../forms/AdManagerForm/pagethree";
import PropTypes from "prop-types";
import React from "react";

const PageOne = ({
  handleNextClick,
  form,
  setForm,
  vidPreview,
  setTotalDays,
  totalDays,
  customDays,
  setCustomDays,
}) => {
  return (
    <div
      className={`col d-flex justify-content-between mx-3 mb-5 ${classes.mobile}`}
    >
      <div className="col-6">
        <video
          src={vidPreview}
          autoPlay
          controls
          className="w-100"
          style={{ borderRadius: "8px" }}
        >
          <track default kind="captions" src="" />
        </video>
      </div>
      <div className="col-5 d-flex">
        <PageThreeForm
          form={form}
          setForm={setForm}
          handleNextClick={handleNextClick}
          setTotalDays={setTotalDays}
          totalDays={totalDays}
          customDays={customDays}
          setCustomDays={setCustomDays}
        />
      </div>
    </div>
  );
};

PageOne.propTypes = {
  handleNextClick: PropTypes.func,
  form: PropTypes.object,
  setForm: PropTypes.func,
  vidPreview: PropTypes.string,
  setTotalDays: PropTypes.func,
  totalDays: PropTypes.number,
  customDays: PropTypes.number,
  setCustomDays: PropTypes.func,
};

export default PageOne;
