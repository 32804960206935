import React, { useEffect, useState } from "react";
import axios from "axios";
import { addToLibrary } from "../../utils/images";
import AppleFeedGenre from "../../services/requests/httpRequest/POC/appleFeedGenre";
import AppleFeedGenreSongs from "../../services/requests/httpRequest/POC/appleFeedGenreSongs";
import AppleFeedSongsGenre from "../../services/requests/httpRequest/POC/appleFeedSongsGenre";
import AppleFeedSongsByGenre from "../../services/requests/httpRequest/POC/appleFeedSongsByGenre";
import AppleFeedSongsSearch from "../../services/requests/httpRequest/POC/appleFeedSongsBySearch";
import PropTypes from "prop-types";
import Button from "../../components/ui/Buttons";

function AppleMusickitPOC() {
  const [musicKitInstance, setMusicKitInstance] = useState(null);
  const [activeTab, setActiveTab] = useState("iframe");
  const [authorized, setAuthorized] = useState(false);
  const [iframeActiveTab, setIframeActiveTab] = useState("albums");
  const [iframeAlbums, setIframeAlbums] = useState([]); // Store searched albums
  const [iframeSongs, setIframeSongs] = useState([]); // Store searched songs
  const [iframeVideos, setIframeVideos] = useState([]); // Store searched videos
  const [playingSongId, setPlayingSongId] = useState(null); // Track currently playing song
  const [playingVideoId, setPlayingVideoId] = useState(null); // Track currently playing video

  // apple feed
  const feedPage = 1;
  const [feedData, setFeedData] = useState([]);
  const [feedGenreId, setFeedGenreId] = useState("");
  const [feedGenreName, setFeedGenreName] = useState("");
  const [genreSongPage, setGenreSongPage] = useState(1);
  const genreSongLimit = 10;
  const [feedGenreSongList, setFeedGenreSongList] = useState([]);
  const [songDetails, setSongDetails] = useState([]);
  const [genreSongSelect, setGenreSongSelect] = useState({});

  // apple music feed type : song
  const [appleMusicFeedTypeSongGenres, setAppleMusicFeedTypeSongGenres] =
    useState([]);
  const [
    appleMusicFeedTypeSongGenreSelect,
    setAppleMusicFeedTypeSongGenreSelect,
  ] = useState("");
  const [
    appleMusicFeedTypeSongGenreSelectData,
    setAppleMusicFeedTypeSongGenreSelectData,
  ] = useState([]);
  const [
    appleMusicFeedTypeSongGenreSelectDataSearch,
    setAppleMusicFeedTypeSongGenreSelectDataSearch,
  ] = useState([]);
  const [appleMusicFeedTypeSongSelect, setAppleMusicFeedTypeSongSelect] =
    useState("");
  // const [searchTermFeed, setSearchTermFeed] = useState("");

  useEffect(() => {
    // Initialize MusicKit when the app loads
    const initializeMusicKit = async () => {
      await window.MusicKit.configure({
        developerToken: await getDeveloperToken(),
        app: {
          name: "altRadar",
          build: "1.0",
        },
      });

      setMusicKitInstance(window.MusicKit.getInstance());
    };
    initializeMusicKit();
  }, []);

  useEffect(() => {
    // Initialize MusicKit when the app loads
    const initializeMusicKit = async () => {
      await window.MusicKit.configure({
        developerToken: await getDeveloperToken(),
        app: {
          name: "altRadar",
          build: "1.0",
        },
      });

      setMusicKitInstance(window.MusicKit.getInstance());
    };
    initializeMusicKit();
  }, [authorized]);

  useEffect(() => {
    if (musicKitInstance) {
      async function authorizeUser() {
        const isAuthorized = await musicKitInstance.authorize();

        if (isAuthorized) {
          localStorage.setItem(
            "appleMusicToken",
            musicKitInstance.musicUserToken
          ); // Save the token
          setAuthorized(true);
        } else {
          setAuthorized(false);
        }
      }

      authorizeUser();
    }
  }, [musicKitInstance]);

  // Fetch developer token from backend
  const getDeveloperToken = async () => {
    const response = await axios.get(
      "https://dev.altradar.org/v1/apple/get-dev-token"
    );
    return response.data.data.developerToken;
  };

  const authorizeUser = async () => {
    try {
      const isAuthorized = await musicKitInstance.authorize();
      if (isAuthorized) {
        setAuthorized(true);
        localStorage.setItem(
          "appleMusicToken",
          musicKitInstance.musicUserToken
        ); // Save the token
      } else {
        setAuthorized(false);
        console.error("Authorization failed or was denied by the user");
      }
    } catch (error) {
      console.log("stringify error ------ ", JSON.stringify(error));
    }
  };

  const searchIframe = async (searchTerm, type) => {
    try {
      if (!musicKitInstance) {
        console.error("MusicKit instance is not initialized.");
        return;
      }
      // Search for albums using the search term
      const response = await musicKitInstance.api.music(
        `/v1/catalog/us/search`,
        { term: searchTerm, types: iframeActiveTab || type }
      );

      const foundData = response.data.results[iframeActiveTab || type].data;
      if (iframeActiveTab === "albums") setIframeAlbums(foundData);
      else if (iframeActiveTab === "songs") setIframeSongs(foundData);
      else if (iframeActiveTab === "music-videos") {
        setIframeVideos(foundData);
      }
    } catch (error) {
      console.error("Error searching albums:", error);
    }
  };

  const playOrPauseSong = async (songId) => {
    if (!musicKitInstance) {
      console.error("MusicKit instance is not initialized.");
      return;
    }

    try {
      if (playingSongId === songId) {
        // If the song is currently playing, pause it
        musicKitInstance.pause();
        setPlayingSongId(null); // Clear playing song
      } else {
        // Play the selected song by ID
        await musicKitInstance.setQueue({ song: songId });
        await musicKitInstance.play();
        const nowPlaying = musicKitInstance.player.nowPlayingItem;
        console.log("Now Playing: ", nowPlaying);
        setPlayingSongId(songId); // Set the currently playing song ID
      }
    } catch (error) {
      console.error("Error playing or pausing the song:", error);
    }
  };

  const playVideo = async (videoId) => {
    if (!musicKitInstance) {
      console.error("MusicKit instance is not initialized.");
      return;
    }

    try {
      if (playingVideoId === videoId) {
        // Pause if the video is already playing
        await musicKitInstance.pause();
        setPlayingVideoId(null);
      } else {
        // Set the queue with the video ID and play
        await musicKitInstance.setQueue({ musicVideo: videoId });
        await musicKitInstance.play();
        setPlayingVideoId(videoId);
      }
    } catch (error) {
      console.error("Error playing the video:", error);
    }
  };

  const addToLibraryFn = async (songId) => {
    if (musicKitInstance && authorized) {
      if (!authorized) {
        await musicKitInstance.authorize(); // Ensure the user is authorized
      }
      try {
        // The correct format for the request to add a song to the library
        await musicKitInstance.api.music(
          "/v1/me/library",
          {
            ids: { songs: [songId] },
            l: "en-us",
          },
          {
            fetchOptions: {
              method: "POST", // Use POST method
            },
          }
        );
        // console.log('Song added to library:', response);
        window.alert("Song added to library");
      } catch (error) {
        console.error("Error adding to library:", error);
      }
    }
  };

  // apple feed
  useEffect(() => {
    const fetchData = async () => {
      if (activeTab === "feed" && feedPage === 1) {
        try {
          const data = await AppleFeedGenre();
          if (data) setFeedData(data?.data);
        } catch (error) {
          console.error("Error fetching feed data:", error);
        }
      }
    };

    fetchData();
  }, [activeTab, feedPage]);

  const getFeedGenreSong = async (genreId, p) => {
    const id = genreId || feedGenreId;
    const page = p || genreSongPage;
    const data = await AppleFeedGenreSongs(id, page, genreSongLimit);
    setFeedGenreSongList(data.data);
  };

  useEffect(() => {
    if (feedGenreId) {
      getFeedGenreSong(feedGenreId, 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedGenreId]);

  const handleFeedGenreId = (id) => {
    setFeedGenreId(id);
  };

  useEffect(() => {
    const fetchSongDetails = async () => {
      try {
        await Promise.all(
          feedGenreSongList.map(async (list) => {
            const songData = await musicKitInstance.api.music(
              `/v1/catalog/us/songs/${list.songId}`
            );
            setSongDetails((prev) => [
              ...prev,
              {
                songId: list.songId,
                ranking: list.ranking,
                name: songData.data.data[0].attributes.name,
                thumbnail: songData.data.data[0].attributes.artwork.url
                  .replace("{w}", "100")
                  .replace("{h}", "100"),
                artistName: songData.data.data[0].attributes.artistName,
                albumName: songData.data.data[0].attributes.albumName,
                composerName: songData.data.data[0].attributes.composerName,
                durationInMillis:
                  songData.data.data[0].attributes.durationInMillis,
                trackNumber: songData.data.data[0].attributes.trackNumber,
              },
            ]);
          })
        );
      } catch (error) {
        console.error("Error fetching song details:", error);
      }
    };

    if (feedGenreSongList.length > 0) {
      fetchSongDetails();
    }
  }, [feedGenreSongList, musicKitInstance]);

  const handleSetGenreSelect = (data) => {
    setGenreSongSelect(data);
  };

  const stopSong = () => {
    if (musicKitInstance) {
      musicKitInstance?.stop();
    }
  };

  useEffect(() => {
    async function getData() {
      const data = await AppleFeedSongsGenre();
      setAppleMusicFeedTypeSongGenres(data);
    }
    if (
      activeTab === "feed-songs" &&
      appleMusicFeedTypeSongGenres.length === 0
    ) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  //   useEffect(() => {
  //     async function getData() {
  //       const data = await AppleFeedSongsByGenre(
  //         appleMusicFeedTypeSongGenreSelect
  //       );
  //       //   console.log("data =--- ", data);
  //       setAppleMusicFeedTypeSongGenreSelectData(data);
  //     }
  //     if (appleMusicFeedTypeSongGenreSelect) {
  //       getData();
  //     }
  //   }, [appleMusicFeedTypeSongGenreSelect]);

  useEffect(() => {
    async function getData() {
      // Fetch data using your function
      const data = await AppleFeedSongsByGenre(
        appleMusicFeedTypeSongGenreSelect
      );

      // Check if data was successfully retrieved
      if (data?.length) {
        // Initialize an array to hold the enriched data
        const enrichedData = [];

        // Use a for...of loop to process each item sequentially
        for (const item of data) {
          try {
            // Fetch song details with MusicKit using song ID
            const songDetails = await musicKitInstance.api.music(
              `/v1/catalog/us/songs/${item.id}`
            );

            // Format the artwork URL by replacing {w}, {h}, and {f} placeholders
            const width = 250;
            const height = 250;
            const artworkURL = songDetails.data.data[0].attributes.artwork.url
              .replace("{w}", width)
              .replace("{h}", height)
              .replace("{f}", "jpg"); // Can also set format as 'png'

            // Log the artwork URL for debugging
            // console.log("artworkURL =--- ", artworkURL);

            // Push the item with added artwork URL into the enrichedData array
            enrichedData.push({ ...item, thumbnail: artworkURL });
          } catch (error) {
            // console.error(`Error fetching artwork for song ${item.id}:`, error);
            // Push the original item if artwork fetching fails
            enrichedData.push(item);
          }
        }

        // Set state with enriched data after all items are processed
        setAppleMusicFeedTypeSongGenreSelectData(enrichedData);
      }
    }

    if (appleMusicFeedTypeSongGenreSelect) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appleMusicFeedTypeSongGenreSelect]);

  const handleFeedSearch = async (string) => {
    async function getData(str) {
      const data = await AppleFeedSongsSearch(str);

      // Check if data was successfully retrieved
      if (data?.length) {
        // Initialize an array to hold the enriched data
        const enrichedData = [];

        // Use a for...of loop to process each item sequentially
        for (const item of data) {
          try {
            // Fetch song details with MusicKit using song ID
            const songDetails = await musicKitInstance.api.music(
              `/v1/catalog/us/songs/${item.id}`
            );

            // Format the artwork URL by replacing {w}, {h}, and {f} placeholders
            const width = 250;
            const height = 250;
            const artworkURL = songDetails.data.data[0].attributes.artwork.url
              .replace("{w}", width)
              .replace("{h}", height)
              .replace("{f}", "jpg"); // Can also set format as 'png'

            // Log the artwork URL for debugging
            // console.log("artworkURL =--- ", artworkURL);

            // Push the item with added artwork URL into the enrichedData array
            enrichedData.push({ ...item, thumbnail: artworkURL });
          } catch (error) {
            // console.error(`Error fetching artwork for song ${item.id}:`, error);
            // Push the original item if artwork fetching fails
            enrichedData.push(item);
          }
        }

        // Set state with enriched data after all items are processed
        setAppleMusicFeedTypeSongGenreSelectDataSearch(enrichedData);
      }
    }
    if (string) {
      await getData(string);
    }
  };

  return (
    <>
      <h1 className="text-center">Apple MusicKit Integration POC</h1>

      <button onClick={authorizeUser} disabled={authorized}>
        {authorized ? "User is Authorized" : "Authorize User"}
      </button>

      <OptionsTabs
        setActiveTab={setActiveTab}
        setFeedGenreId={setFeedGenreId}
        setFeedGenreName={setFeedGenreName}
        setSongDetails={setSongDetails}
        setGenreSongSelect={setGenreSongSelect}
        stopSong={stopSong}
        setAppleMusicFeedTypeSongGenreSelect={
          setAppleMusicFeedTypeSongGenreSelect
        }
        setAppleMusicFeedTypeSongGenreSelectData={
          setAppleMusicFeedTypeSongGenreSelectData
        }
        setAppleMusicFeedTypeSongGenreSelectDataSearch={
          setAppleMusicFeedTypeSongGenreSelectDataSearch
        }
        setAppleMusicFeedTypeSongSelect={setAppleMusicFeedTypeSongSelect}
      />

      {/* Video Container for MusicKit */}

      {(activeTab === "musickit-songs" ||
        activeTab === "musickit-videos" ||
        activeTab === "iframe") && (
        <InternalSearch
          searchIframe={searchIframe}
          setIframeActiveTab={setIframeActiveTab}
        />
      )}
      {activeTab === "musickit-songs" && (
        <div className="d-flex flex-row justify-content-evenly">
          <SongList
            songs={iframeSongs}
            playOrPauseSong={playOrPauseSong}
            playingSongId={playingSongId}
            addToLibraryFn={addToLibraryFn}
          />
          <apple-music-card-player theme="auto"></apple-music-card-player>
          <div style={{ backgroundColor: "darkgray" }}>
            <apple-music-artwork
              alt="Fearless (Taylor's Version)"
              source={
                iframeSongs.find((song) => song.attributes.id === playingSongId)
                  ?.attributes.artwork?.url
              }
              width="250"
            ></apple-music-artwork>
            <apple-music-playback-controls />
            <apple-music-progress></apple-music-progress>
            <apple-music-volume></apple-music-volume>
          </div>
        </div>
      )}
      {activeTab === "musickit-videos" && (
        <div className="d-flex flex-row justify-content-evenly">
          <VideoList
            videos={iframeVideos}
            playVideo={playVideo}
            playingVideoId={playingVideoId}
          />

          <div style={{ height: "350px" }}>
            <apple-music-video-player takeover="false"></apple-music-video-player>
          </div>
        </div>
      )}
      {activeTab === "iframe" && (
        <>
          {/* <Albums data={albums} /> */}

          <Iframes
            iframeAlbums={iframeAlbums}
            iframeSongs={iframeSongs}
            iframeVideos={iframeVideos}
            searchIframe={searchIframe}
            setIframeActiveTab={setIframeActiveTab}
            iframeActiveTab={iframeActiveTab}
          />
        </>
      )}
      {activeTab === "feed" && feedGenreId === "" && (
        <>
          <div className="text-center p-3 m-4">
            <h2>Showing top chart songs genres (US region)</h2>
          </div>
          <FeedGenreList
            feedData={feedData}
            feedGenre={feedGenreId}
            handleFeedGenreId={handleFeedGenreId}
            setFeedGenreName={setFeedGenreName}
          />
        </>
      )}
      {feedGenreId && (
        <>
          <div className="text-center p-3 m-4">
            <h2>
              Showing top chart songs
              <br />
              Seleteced genre is {feedGenreName}
            </h2>
          </div>
          <div className="col-12 d-flex justify-content-between">
            <div className="col-3">
              <Song
                data={songDetails}
                setGenreSongSelect={handleSetGenreSelect}
                setGenreSongPage={setGenreSongPage}
                setSongDetails={setSongDetails}
                getFeedGenreSong={getFeedGenreSong}
                feedGenreId={feedGenreId}
                page={genreSongPage}
                playOrPauseSong={playOrPauseSong}
              />
            </div>
            {genreSongSelect?.artistName && (
              <div className="col-8">
                <SongDetails data={genreSongSelect} />
              </div>
            )}
          </div>
        </>
      )}

      {/* music feed songs */}
      {activeTab === "feed-songs" && (
        <div className="text-center">
          Apple music feed : songs data parquet file 1
          <InternalSearchFeedTypeSong
            // setSearchTermFeed={setSearchTermFeed}
            handleFeedSearch={handleFeedSearch}
            setAppleMusicFeedTypeSongGenreSelectData={
              setAppleMusicFeedTypeSongGenreSelectData
            }
            setAppleMusicFeedTypeSongGenreSelectDataSearch={
              setAppleMusicFeedTypeSongGenreSelectDataSearch
            }
            setAppleMusicFeedTypeSongSelect={setAppleMusicFeedTypeSongSelect}
            stopSong={stopSong}
          />
        </div>
      )}
      {activeTab === "feed-songs" &&
        !appleMusicFeedTypeSongGenreSelect &&
        !appleMusicFeedTypeSongGenreSelectDataSearch.length && (
          <>
            {appleMusicFeedTypeSongGenres ? (
              <FeedTypeSongGenreList
                feedData={appleMusicFeedTypeSongGenres}
                setAppleMusicFeedTypeSongGenreSelect={
                  setAppleMusicFeedTypeSongGenreSelect
                }
              />
            ) : (
              <>Loading genres...</>
            )}
          </>
        )}
      {appleMusicFeedTypeSongGenreSelect ? (
        // Render something when the array has items
        <>
          {appleMusicFeedTypeSongGenreSelectData.length ? (
            <div className="col-12 d-flex justify-content-between">
              <div className="col-3">
                <AppleFeedTypeSongList
                  data={appleMusicFeedTypeSongGenreSelectData}
                  setAppleMusicFeedTypeSongSelect={
                    setAppleMusicFeedTypeSongSelect
                  }
                  playOrPauseSong={playOrPauseSong}
                />
              </div>
              {appleMusicFeedTypeSongSelect && (
                <div className="col-8">
                  <SongDetailsFeed data={appleMusicFeedTypeSongSelect} />
                </div>
              )}
            </div>
          ) : (
            <>Loading songs...</>
          )}
        </>
      ) : (
        // Render something else when the array is empty
        <p>Loading songs...</p>
      )}
      {appleMusicFeedTypeSongGenreSelectDataSearch ? (
        <div className="col-12 d-flex justify-content-between">
          <div className="col-3">
            <AppleFeedTypeSongList
              data={appleMusicFeedTypeSongGenreSelectDataSearch}
              setAppleMusicFeedTypeSongSelect={setAppleMusicFeedTypeSongSelect}
              playOrPauseSong={playOrPauseSong}
            />
          </div>
          {appleMusicFeedTypeSongSelect && (
            <div className="col-8">
              <SongDetailsFeed data={appleMusicFeedTypeSongSelect} />
            </div>
          )}
        </div>
      ) : (
        <>Loading searched songs...</>
      )}
    </>
  );
}

const OptionsTabs = ({
  setActiveTab,
  setFeedGenreId,
  setFeedGenreName,
  setSongDetails,
  setGenreSongSelect,
  stopSong,
  setAppleMusicFeedTypeSongGenreSelect,
  setAppleMusicFeedTypeSongGenreSelectData,
  setAppleMusicFeedTypeSongGenreSelectDataSearch,
  setAppleMusicFeedTypeSongSelect,
}) => {
  return (
    <div className="container m-2 text-center">
      <div className="row">
        <div className="col">
          <button
            className="btn btn-primary"
            onClick={() => setActiveTab("iframe")}
          >
            iframes
          </button>
        </div>
        <div className="col">
          <button
            className="btn btn-secondary"
            onClick={() => setActiveTab("musickit-songs")}
          >
            musickit songs
          </button>
        </div>
        <div className="col">
          <button
            className="btn btn-secondary"
            onClick={() => setActiveTab("musickit-videos")}
          >
            musickit videos
          </button>
        </div>
        <div className="col">
          <button
            className="btn btn-success"
            onClick={() => {
              stopSong();
              setActiveTab("feed");
              setFeedGenreId("");
              setFeedGenreName("");
              setSongDetails([]);
              setGenreSongSelect({});
            }}
          >
            feed Top Charts
          </button>
        </div>
        <div className="col">
          <button
            className="btn btn-secondary"
            onClick={() => {
              setActiveTab("feed-songs");
              setAppleMusicFeedTypeSongGenreSelect("");
              setAppleMusicFeedTypeSongGenreSelectData([]);
              setAppleMusicFeedTypeSongGenreSelectDataSearch([]);
              setAppleMusicFeedTypeSongSelect({});
            }}
          >
            feed Songs File 1
          </button>
        </div>
      </div>
    </div>
  );
};

// Component to render search input and iframes for albums
const Iframes = ({
  iframeAlbums,
  iframeSongs,
  iframeVideos,
  iframeActiveTab,
}) => {
  return (
    <>
      {/* Render iframes for each album */}
      <div
        className="album-iframes mt-3"
        style={{
          display: "flex",
          overflowX: "scroll", // Enable horizontal scrolling
          padding: "10px 0",
        }}
      >
        {iframeActiveTab === "albums" &&
          iframeAlbums.length > 0 &&
          iframeAlbums.map((album) => (
            <div
              key={"iframeAlbi" + album.id}
              style={{
                minWidth: "500px", // Set a fixed width for each iframe container
                marginRight: "10px", // Space between iframes
              }}
            >
              {
                <iframe
                  title={album.attributes.name}
                  src={`https://embed.music.apple.com/us/album/${
                    album.id
                  }?app=music&musicUserToken=${localStorage.getItem(
                    "appleMusicToken"
                  )}`}
                  height="450" // Increase height to show full iframe
                  width="100%" // Adjust width to fit the container
                  style={{ border: "0", borderRadius: "12px" }}
                  allow="autoplay *; encrypted-media *; clipboard-write"
                  sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation"
                ></iframe>
              }
            </div>
          ))}

        {iframeActiveTab === "songs" &&
          iframeSongs.length > 0 &&
          iframeSongs.map((song) => (
            <div
              key={"isongma" + song.id}
              style={{
                minWidth: "300px", // Set a fixed width for each iframe container
                marginRight: "10px", // Space between iframes
              }}
            >
              <iframe
                title={song.attributes.name}
                src={`https://embed.music.apple.com/us/song/${
                  song.id
                }?app=music&musicUserToken=${localStorage.getItem(
                  "appleMusicToken"
                )}`}
                height="200" // Adjust height for songs
                width="100%" // Adjust width to fit the container
                style={{ border: "0", borderRadius: "12px" }}
                allow="autoplay *; encrypted-media *; clipboard-write"
              ></iframe>
            </div>
          ))}

        {iframeActiveTab === "music-videos" && iframeVideos.length > 0 && (
          <div style={{ display: "flex", overflowX: "scroll" }}>
            {iframeVideos.map((video) => (
              <div
                key={"ivid" + video.id}
                style={{
                  minWidth: "500px", // Set a fixed width for each iframe container
                  marginRight: "10px", // Space between iframes
                }}
              >
                <iframe
                  title={video.attributes.name}
                  src={`https://embed.music.apple.com/us/music-video/${
                    video.id
                  }?app=music&musicUserToken=${localStorage.getItem(
                    "appleMusicToken"
                  )}`}
                  height="450" // Adjust height for full video display
                  width="100%" // Fit the container width
                  style={{ border: "0", borderRadius: "12px" }}
                  allow="autoplay *; encrypted-media *; clipboard-write"
                ></iframe>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

const SongList = ({
  songs,
  playOrPauseSong,
  playingSongId,
  addToLibraryFn,
}) => {
  return (
    <div className="song-list mt-3">
      {songs.length > 0 ? (
        songs.map((song) => (
          <div
            key={"songlen" + song.id}
            className="song-item mb-2"
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button
              className={"blank"}
              label={
                <div>
                  <apple-music-artwork
                    alt="Fearless (Taylor's Version)"
                    source={song.attributes.artwork.url}
                    width="60"
                  ></apple-music-artwork>
                  {/* <apple-music-artwork-lockup type="song" content-id={song.id}></apple-music-artwork-lockup> */}
                  <div>
                    <p>
                      {song.attributes.name} <br /> {song.attributes.artistName}
                    </p>
                  </div>
                  {/* <button
                                    className="btn btn-primary"
                                    onClick={() => playOrPauseSong(song.id)}
                                >
                                    {playingSongId === song.id ? 'Pause' : 'Play'}
                                </button> */}
                </div>
              }
              handleClick={() => playOrPauseSong(song.id)}
            />
            <Button
              className={"blank"}
              label={<img src={addToLibrary} width={20} alt="add to library" />}
              handleClick={() => addToLibraryFn(song.id)}
            />
          </div>
        ))
      ) : (
        <p>No songs found. Try searching for something else.</p>
      )}
    </div>
  );
};

// Render video list with thumbnails and play/pause buttons
const VideoList = ({ videos, playVideo, playingVideoId }) => {
  return (
    <div
      className="video-list mt-3"
      style={{ display: "flex", flexDirection: "column", overflowX: "scroll" }}
    >
      {videos.length > 0 ? (
        videos.map((video) => (
          <Button
            key={"vid" + video.id}
            className={"blank"}
            label={
              <div
                key={"vidMaps" + video.id}
                style={{
                  minWidth: "300px",
                  marginRight: "10px",
                  cursor: "pointer",
                }}
              >
                <img
                  src={video.attributes.artwork.url
                    .replace("{w}", "300")
                    .replace("{h}", "300")}
                  alt={video.attributes.name}
                  style={{ width: "100%", borderRadius: "12px" }}
                />
                <p>{video.attributes.name}</p>
                <p>{video.attributes.artistName}</p>
                {/* <button
                              className="btn btn-primary"
                              onClick={() => playVideo(video.id)}
                          >
                              {playingVideoId === video.id ? 'Pause Video' : 'Play Video'}
                          </button> */}
              </div>
            }
            handleClick={() => playVideo(video.id)}
          />
        ))
      ) : (
        <p>No videos found. Try searching for something else.</p>
      )}
    </div>
  );
};

const InternalSearch = ({ searchIframe, setIframeActiveTab }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (type) => {
    if (searchTerm) {
      setIframeActiveTab(type);
      searchIframe(searchTerm, type);
    }
  };

  return (
    <>
      <input
        type="text"
        placeholder="Search for albums"
        value={searchTerm}
        onChange={(e) => {
          setSearchTerm(e.target.value);
        }}
        style={{ width: "100%", padding: "10px", margin: "10px 0" }}
      />
      <button
        className="btn btn-primary m-2"
        onClick={() => {
          handleSearch("albums");
        }}
      >
        Search Albums
      </button>
      <button
        className="btn btn-primary m-2"
        onClick={() => {
          handleSearch("songs");
        }}
      >
        Search Songs
      </button>
      <button
        className="btn btn-primary m-2"
        onClick={() => {
          handleSearch("music-videos");
        }}
      >
        Search Music Videos
      </button>
    </>
  );
};

const FeedGenreList = ({
  feedData,
  feedGenre,
  handleFeedGenreId,
  setFeedGenreName,
}) => {
  return (
    <div className="d-flex flex-wrap flex-row">
      {feedData ? (
        feedData?.map((list) => (
          <div className="col p-2 m-2" key={"fld" + list._id}>
            <button
              className="btn btn-success"
              onClick={() => {
                handleFeedGenreId(list._id);
                setFeedGenreName(list.genreName);
              }}
            >
              {list.genreName}
            </button>
          </div>
        ))
      ) : (
        <></>
      )}
    </div>
  );
};

const Song = ({
  data,
  setGenreSongSelect,
  setGenreSongPage,
  setSongDetails,
  feedGenreId,
  page,
  getFeedGenreSong,
  playOrPauseSong,
}) => {
  // Sort the data by ranking in ascending order
  const sortedData = [...data].sort((a, b) => a.ranking - b.ranking);

  return (
    <>
      <div className="p-2 fw-bolder fst-italic">Click on a song to play</div>
      {sortedData ? (
        sortedData.map((song) => (
          <>
            <Button
              className={"blank"}
              label={
                <div
                  key={"sangMap" + song.songId}
                  className="p-2 m-1"
                  style={{ borderRadius: "10px", border: "2px solid white" }}
                >
                  <img src={song.thumbnail} alt={song.name} />
                  <div>{song.name}</div>
                  <div>Ranking: {song.ranking}</div>
                </div>
              }
              handleClick={() => {
                setGenreSongSelect(song);
                playOrPauseSong(song.songId);
              }}
            />
            {/* <button
                            onClick={() => {
                                playOrPauseSong(song.songId);
                                setGenreSongSelect(song);
                            }}
                        >
                            Play
                        </button> */}
          </>
        ))
      ) : (
        <div>Loading ...</div>
      )}

      <button
        className="btn btn-secondary"
        onClick={() => {
          setGenreSongPage((prev) => prev + 1);
          setSongDetails([]);
          setGenreSongSelect({});
          getFeedGenreSong(feedGenreId, page + 1);
        }}
      >
        Next Page
      </button>
    </>
  );
};

const SongDetails = ({ data }) => {
  const thumnail = data.thumbnail.replace("100x100", "{w}x{h}");
  return (
    <div className="d-flex flex-row m-2 p-2">
      <div
        className="col-6 text-center m-2 p-2"
        style={{ borderRadius: "10px", border: "2px solid red" }}
      >
        <img src={data.thumbnail} alt={data.name} />
        <div>{data.name}</div>
        <div>Ranking: {data.ranking}</div>
        <div>Artist Name: {data.artistName}</div>
        <div>Album Name: {data.albumName}</div>
        <div>Composer Name: {data.composerName}</div>
        <div>Duration: {data.durationInMillis} ms</div>
        <div>Track Number: {data.trackNumber}</div>
      </div>

      <div
        key={data?.songId}
        className="col-6 d-flex flex-row justify-content-evenly"
      >
        <div
          style={{ backgroundColor: "darkgray" }}
          className="d-flex flex-column justify-content-center"
        >
          {/* <apple-music-artwork-lockup key={data.songId} type="song" content-id={data.songId}></apple-music-artwork-lockup> */}
          <apple-music-artwork
            alt={data.name}
            source={thumnail}
            width="300"
          ></apple-music-artwork>
          <apple-music-playback-controls />
          <apple-music-progress></apple-music-progress>
          <apple-music-volume></apple-music-volume>
        </div>
      </div>
    </div>
  );
};

const FeedTypeSongGenreList = ({
  feedData,
  setAppleMusicFeedTypeSongGenreSelect,
}) => {
  return (
    <div className="d-flex flex-wrap flex-row">
      {feedData ? (
        feedData?.map((genre) => (
          <div className="col p-2 m-2" key={"genmap" + genre}>
            <button
              className="btn btn-success"
              onClick={() => {
                // handleFeedGenreId(list._id);
                setAppleMusicFeedTypeSongGenreSelect(genre);
              }}
            >
              {genre}
            </button>
          </div>
        ))
      ) : (
        <></>
      )}
    </div>
  );
};

const AppleFeedTypeSongList = ({
  data,
  setAppleMusicFeedTypeSongSelect,
  playOrPauseSong,
}) => {
  return (
    <>
      {data.map((obj, index) => (
        <>
          {/* {console.log(obj)} */}
          {/* <apple-music-artwork-lockup
            key={obj.id}
            type="song"
            content-id={obj.id}
          ></apple-music-artwork-lockup> */}
          <Button
            className={"blank"}
            label={
              <div key={obj.nameDefault}>
                <apple-music-artwork
                  alt={obj.nameDefault}
                  source={obj.thumbnail}
                  width="300"
                ></apple-music-artwork>
                <div key={obj?.nameDefault}>{obj?.nameDefault}</div>
              </div>
            }
            handleClick={() => {
              setAppleMusicFeedTypeSongSelect(obj);
              playOrPauseSong(obj.id);
            }}
          />
        </>
      ))}
    </>
  );
};

const SongDetailsFeed = ({ data }) => {
  const thumnail = String(data.thumbnail).replace("250x250", "{w}x{h}");
  return (
    <div className="d-flex flex-row m-2 p-2">
      <div
        className="col-6 text-center m-2 p-2"
        style={{ borderRadius: "10px", border: "2px solid red" }}
      >
        <img src={data?.thumbnail} alt={data.nameDefault} />
        <div>Name: {data?.nameDefault}</div>
        <div>Album: {data?.album?.name ? data?.album?.name : "N/A"}</div>
        <div>Copyright: {data?.copyright ? data?.copyright : "N/A"}</div>
        {data?.genres?.map((genre) => (
          <div key={"gengen" + genre?.id}>Genre: {genre?.name}</div>
        ))}
        {/* <div>Genre: {data?.genre?.name ? data?.genre?.name : "N/A"}</div> */}
        <div>
          Explicit:{" "}
          {data?.parentalAdvisoryType ? data?.parentalAdvisoryType : "N/A"}
        </div>
        {data?.primaryArtists?.map((artist) => (
          <div key={"primart" + artist?.id}>Primary Artist: {artist?.name}</div>
        ))}
        {/* <div>Primary Artist: {data?.primaryArtists[0]?.name}</div> */}
        <div>
          Release Date:{" "}
          {data?.releaseDate?.default ? data?.releaseDate?.default : "N/A"}
        </div>
      </div>
      <div
        key={data?.id}
        className="col-6 d-flex flex-row justify-content-evenly"
      >
        <div
          style={{ backgroundColor: "darkgray" }}
          className="d-flex flex-column justify-content-center"
        >
          {/* <apple-music-artwork-lockup key={data.songId} type="song" content-id={data.songId}></apple-music-artwork-lockup> */}
          <apple-music-artwork
            alt={data.nameDefault}
            source={thumnail}
            width="300"
          ></apple-music-artwork>
          <apple-music-playback-controls />
          <apple-music-progress></apple-music-progress>
          <apple-music-volume></apple-music-volume>
        </div>
      </div>
    </div>
  );
};

const InternalSearchFeedTypeSong = ({
  // setSearchTermFeed,
  handleFeedSearch,
  setAppleMusicFeedTypeSongGenreSelectData,
  setAppleMusicFeedTypeSongGenreSelectDataSearch,
  setAppleMusicFeedTypeSongSelect,
  stopSong,
}) => {
  const [searchTerm, setSearchTerm] = useState("");

  return (
    <>
      <input
        type="text"
        placeholder="Search for songs"
        value={searchTerm}
        onChange={(e) => {
          setSearchTerm(e.target.value);
        }}
        style={{ width: "100%", padding: "10px", margin: "10px 0" }}
      />
      <button
        className="btn btn-primary m-2"
        onClick={() => {
          setAppleMusicFeedTypeSongGenreSelectData([]);
          setAppleMusicFeedTypeSongGenreSelectDataSearch([]);
          setAppleMusicFeedTypeSongSelect({});
          stopSong();
          handleFeedSearch(searchTerm);
        }}
      >
        Search Songs
      </button>
    </>
  );
};

// ------------ PropTypes ------------

OptionsTabs.propTypes = {
  setActiveTab: PropTypes.func,
  setFeedGenreId: PropTypes.func,
  setFeedGenreName: PropTypes.func,
  setSongDetails: PropTypes.func,
  setGenreSongSelect: PropTypes.func,
  stopSong: PropTypes.func,
  setAppleMusicFeedTypeSongGenreSelect: PropTypes.func,
  setAppleMusicFeedTypeSongGenreSelectData: PropTypes.func,
  setAppleMusicFeedTypeSongGenreSelectDataSearch: PropTypes.func,
  setAppleMusicFeedTypeSongSelect: PropTypes.func,
};

Iframes.propTypes = {
  iframeAlbums: PropTypes.array,
  iframeSongs: PropTypes.array,
  iframeVideos: PropTypes.array,
  iframeActiveTab: PropTypes.string,
};

SongList.propTypes = {
  songs: PropTypes.array,
  playOrPauseSong: PropTypes.func,
  playingSongId: PropTypes.string,
  addToLibraryFn: PropTypes.func,
};

VideoList.propTypes = {
  videos: PropTypes.array,
  playVideo: PropTypes.func,
  playingVideoId: PropTypes.string,
};

InternalSearchFeedTypeSong.propTypes = {
  handleFeedSearch: PropTypes.func,
  setAppleMusicFeedTypeSongGenreSelectData: PropTypes.func,
  setAppleMusicFeedTypeSongGenreSelectDataSearch: PropTypes.func,
  setAppleMusicFeedTypeSongSelect: PropTypes.func,
  stopSong: PropTypes.func,
};

InternalSearch.propTypes = {
  searchIframe: PropTypes.func,
  setIframeActiveTab: PropTypes.func,
};

FeedGenreList.propTypes = {
  feedData: PropTypes.array,
  feedGenre: PropTypes.string,
  handleFeedGenreId: PropTypes.func,
  setFeedGenreName: PropTypes.func,
};

Song.propTypes = {
  data: PropTypes.object,
  setGenreSongSelect: PropTypes.func,
  setGenreSongPage: PropTypes.func,
  setSongDetails: PropTypes.func,
  feedGenreId: PropTypes.string,
  page: PropTypes.number,
  getFeedGenreSong: PropTypes.func,
  playOrPauseSong: PropTypes.func,
};

SongDetails.propTypes = {
  data: PropTypes.object,
};

FeedTypeSongGenreList.propTypes = {
  feedData: PropTypes.array,
  setAppleMusicFeedTypeSongGenreSelect: PropTypes.func,
};

AppleFeedTypeSongList.propTypes = {
  data: PropTypes.array,
  setAppleMusicFeedTypeSongSelect: PropTypes.func,
  playOrPauseSong: PropTypes.func,
};

SongDetailsFeed.propTypes = {
  data: PropTypes.object,
};

export default AppleMusickitPOC;
