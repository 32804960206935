import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types"; // Capitalize for convention
import React from "react";
import Button from "../ui/Buttons";

const GoBackComponent = ({ handleBack }) => {
  const navigate = useNavigate();
  return (
    <div style={{ cursor: "pointer", width: "fit-content" }}>
      <Button
        className="blank"
        label={<i className="fa fa-angle-left"></i>}
        handleClick={() => (handleBack ? handleBack() : navigate(-1))}
      />
    </div>
  );
};

// Correctly define propTypes
GoBackComponent.propTypes = {
  handleBack: PropTypes.func, // Capitalized "PropTypes" and corrected "prototypes"
};

export default GoBackComponent;
