import classes from "./AdDemographics.module.css";
import PropTypes from "prop-types";
import React from "react";
import Button from "../../ui/Buttons";

const AdDemographicsTabs = ({ handleActiveTab, activeTab }) => {
  const handleClickFn = () => {
    !activeTab && handleActiveTab(activeTab);
  };

  const handleClickFn2 = () => {
    activeTab && handleActiveTab(activeTab);
  };

  return (
    <div className={`col-8 ${classes.tabs} pt-3`}>
      <Button
        className={"blank"}
        label={
          <span
            className={
              activeTab ? `${classes.tab} ${classes.activeTab}` : classes.tab
            }
          >
            Ad Info
          </span>
        }
        handleClick={handleClickFn}
      />
      <Button
        className={"blank"}
        label={
          <span
            className={
              !activeTab ? `${classes.tab} ${classes.activeTab}` : classes.tab
            }
          >
            Ad Analytics
          </span>
        }
        handleClick={handleClickFn2}
      />
    </div>
  );
};

AdDemographicsTabs.propTypes = {
  handleActiveTab: PropTypes.func,
  activeTab: PropTypes.number,
};

export default AdDemographicsTabs;
