import Footer from "../../../components/footerSection";
import Footer2 from "../../../components/footer2Section";
import React from "react";

const FooterSection = () => {
  return (
    <div>
      <Footer />
      <Footer2 />
    </div>
  );
};

export default FooterSection;
