import classes from "./AdManager.module.css";
import PageOneForm from "../../forms/AdManagerForm/pageone";
import sampleImage from "../../../assets/images/svg/adManagerSample.svg";
import PropTypes from "prop-types";
import React from "react";
const PageOne = ({
  form,
  setForm,
  handleNextClick,
  setImgPreview,
  setVidPreview,
  imgPreview,
  videPreview,
}) => {
  return (
    <div
      className={`col d-flex justify-content-between mx-3 mb-5 ${classes.mobile}`}
    >
      <div className="col-6 gap-3 d-flex flex-column">
        {videPreview ? (
          <video
            src={videPreview}
            autoPlay
            controls
            className="w-100"
            style={{ borderRadius: "8px" }}
          >
            <track default kind="captions" src="" />
          </video>
        ) : (
          <img className="w-100" src={sampleImage} alt="" />
        )}
        <img
          className="w-100"
          src={imgPreview || sampleImage}
          alt=""
          style={{ borderRadius: "8px" }}
        />
      </div>
      <div className="col-5 d-flex">
        <PageOneForm
          form={form}
          setForm={setForm}
          handleNextClick={handleNextClick}
          setImgPreview={setImgPreview}
          setVidPreview={setVidPreview}
          imgPreview={imgPreview}
          videPreview={videPreview}
        />
      </div>
    </div>
  );
};

PageOne.propTypes = {
  form: PropTypes.object,
  setForm: PropTypes.func,
  handleNextClick: PropTypes.func,
  setImgPreview: PropTypes.func,
  setVidPreview: PropTypes.func,
  imgPreview: PropTypes.string,
  videPreview: PropTypes.string,
};

export default PageOne;
