import classes from "./AdDemographics.module.css";
import sampleImage from "../../../assets/images/svg/adDemographicsVidSample.svg";
import PropTypes from "prop-types";
import React from "react";

const AdDemographicsVideo = ({ title, videoUrl }) => {
  return (
    <div>
      <h2 className={classes.vidTitle}>{`Title of video lorem ipsum`}</h2>
      {/* Replace with video player when making functional */}
      <img className="w-100" src={sampleImage} alt="" />
    </div>
  );
};

AdDemographicsVideo.propTypes = {
  title: PropTypes.string,
  videoUrl: PropTypes.string,
};

export default AdDemographicsVideo;
