import React, { useEffect, useRef } from "react";
import VideoJs from "./video";
import videojs from "video.js";
import "videojs-youtube";
import AddWatchLater from "../../services/requests/httpRequest/addWatchLater";
import { useDispatch } from "react-redux";
import { setVideoShareState } from "../../store/redux/application/actions";
import VideoReactionCall from "../../services/requests/httpRequest/VideoReactionCall";
import PropTypes from "prop-types";

const Player = (props) => {
  const dispatch = useDispatch();
  const playerRef = useRef(null);

  // Determine if the video URL is a YouTube URL
  const isYouTubeUrl = (url) => {
    const check =
      /youtube\.com\/watch\?v=/.test(url) || /youtu\.be\//.test(url);
    return check;
  };

  const videoJsOptions = {
    title: "VideoJS Player",
    autoplay: true,
    controls: true,
    responsive: false,
    fluid: true,
    sources: [
      {
        src: props.videoUrl,
        type: isYouTubeUrl(props.videoUrl) ? "video/youtube" : "video/mp4",
      },
    ],
    aspectRatio: "16:9",
    ...(isYouTubeUrl(props.videoUrl) && {
      techOrder: ["youtube"],
      youtube: {
        iv_load_policy: 3, // Disable video annotations
        modestbranding: 1, // Restrict branding
        rel: 0, // Related videos will be from the same channel
        disablekb: 1, // Disable keyboard controls
        controls: 1, // Show controls
        fs: 0, // Disable fullscreen button
      },
    }),
  };

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    // You can handle player events here, for example:
    player.on("waiting", () => {
      videojs.log("player is waiting");
    });

    player.on("dispose", () => {
      videojs.log("player will dispose");
    });
  };

  const addWatchLater = async () => {
    AddWatchLater({
      videoId: props.videoId,
      videoName: props.title,
      videoThumbnail: props.thumbnailUrl,
    });
  };

  const shareVideo = () => {
    dispatch(setVideoShareState(true));
  };

  useEffect(() => {
    // create reaction of vide
    VideoReactionCall({
      reactedOn: props.videoId,
      reactionType: "VIEW",
      isUnreacted: false,
      reactionStatus: "NONE",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <VideoJs
      options={videoJsOptions}
      onReady={handlePlayerReady}
      addWatchLater={addWatchLater}
      shareVideo={shareVideo}
      showShareButton={props?.showShareButton}
      showAddWatchLaterButton={props?.showAddWatchLaterButton}
      showNextButton={props?.showNextButton}
    />
  );
};

Player.propTypes = {
  videoUrl: PropTypes.string,
  videoId: PropTypes.string,
  title: PropTypes.string,
  thumbnailUrl: PropTypes.string,
  showShareButton: PropTypes.bool,
  showAddWatchLaterButton: PropTypes.bool,
  showNextButton: PropTypes.bool,
};

export default Player;
