import classes from "./AdManager.module.css";
import PageTwoForm from "../../forms/AdManagerForm/pagetwo";
import PropTypes from "prop-types";
import React from "react";

const PageTwo = ({ handleNextClick, vidPreview, form, setForm }) => {
  return (
    <div
      className={`col d-flex justify-content-between mx-3 mb-5 ${classes.mobile}`}
    >
      <div className="col-6">
        <video
          src={vidPreview}
          autoPlay
          controls
          className="w-100"
          style={{ borderRadius: "8px" }}
        >
          <track default kind="captions" src="" />
        </video>
      </div>
      <div className="col-5 d-flex">
        <PageTwoForm
          form={form}
          setForm={setForm}
          handleNextClick={handleNextClick}
        />
      </div>
    </div>
  );
};

PageTwo.propTypes = {
  handleNextClick: PropTypes.func,
  vidPreview: PropTypes.string,
  form: PropTypes.object,
  setForm: PropTypes.func,
};

export default PageTwo;
