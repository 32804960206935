import classes from './AdManager.module.css';
import GoBackComponent from '../../GoBack';
import SearchBar from './SearchBar';
import AddNew from './AddNew';
import VideoCard from '../../ui/videoCard/videoCard';
import live1 from '../../../assets/images/svg/RadarPage/live1.svg';
import React from 'react';

const AdvertisementComponent = () => {

    const dummySet = Array.from({ length: 50 }, (_, index) => (
        {
            videoInfo: {
                thumbnail: live1
            }
        }
    ))
    return (
        <>

            <div className={classes.advertisementDiv1}>
                <GoBackComponent />
                <span className={classes.title}>view ads</span>
                <div className='d-flex gap-2 p-1 justify-content-center'>
                    <SearchBar />
                    <AddNew />
                </div>
            </div>
            <div className={classes.advertisementCards}>
                <VideoCard videoData={dummySet} />
            </div>


        </>
    )
}

export default AdvertisementComponent;