import Button from "../ui/Buttons";
import classes from "./Form.module.css";
import React, { useState } from "react";
import MailSendToken from "../../services/requests/httpRequest/MailSendToken";
import { useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  const Navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  const handleClick = async (e) => {
    e.preventDefault();
    if (email === "") {
      setError("Email is required");
      return;
    }
    let result = await MailSendToken(email, "forgotPassword");
    if (result === 200) {
      setError("");
      Navigate("/");
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setError("");
  };

  return (
    <div className="container-fluid">
      <form action="" className="py-3 px-3">
        <h1 className={`${classes.heading} text-center`}>Forgot Password ?</h1>
        <h2 className="px-4 py-4 fs-6">
          Enter your registered email id. reset password link will be sent.
        </h2>
        <input
          className={`${classes.inputBox}`}
          type="email"
          placeholder="Enter your Email"
          value={email}
          onChange={(e) => {
            handleEmailChange(e);
          }}
        />
        {error.length > 0 && (
          <span className={classes.inputErrorMessage}>{error}</span>
        )}
        <Button
          type="submit"
          label="Submit"
          className={"cardButton active"}
          handleClick={handleClick}
        ></Button>
      </form>
    </div>
  );
};

export default ForgotPassword;
