import { useState, useEffect } from 'react';
import { socket } from './index';
import { setUploadVideoSnapshots, setSnapshotId } from '../../store/redux/application/actions';
import { useDispatch } from 'react-redux';

const SocketIO = () => {
    const dispatch = useDispatch();
    const [isConnected, setIsConnected] = useState(socket.connected);

    useEffect(() => {
        function onConnect() {
            setIsConnected(true);
        }

        function onDisconnect() {
            setIsConnected(false);
            console.log(
                '%c\u25CF Socket Disconnected',
                'color: red; font-size: 16px; font-weight: bold;'
            );
        }

        socket.on('connect', onConnect);
        socket.on('disconnect', onDisconnect);

        // Handle thumbnail generated event
        socket.on('THUMBNAIL_GENERATED', (data) => {
            dispatch(setSnapshotId(data.FileId));
            dispatch(setUploadVideoSnapshots(data.uris)); // Handling snapshot generation
        });

        return () => {
            socket.off('connect', onConnect);
            socket.off('disconnect', onDisconnect);
            socket.off('THUMBNAIL_GENERATED', onDisconnect);
        };
    }, [dispatch,]);

    if (isConnected) {
        console.log(
            '%c\u25CF Socket Connected',
            'color: green; font-size: 16px; font-weight: bold;'
        );
    }

    return null; // or return some UI if needed
};

export default SocketIO;
