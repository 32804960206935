import classes from "../AdDemographics.module.css";
import { Line } from "react-chartjs-2";
import Select from "react-select";
import React from 'react';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  ArcElement,
  LineElement,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

const NoOfClicks = () => {
  const lineGraphData = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
    ],
    datasets: [
      {
        label: "Followers",
        data: [30, 70, 50, 40, 60, 80, 75, 65, 55, 45],
        fill: false,
        borderColor: "#293276",
      },
    ],
  };

  const lineGraphOptions = {
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const selectStyle2 = {
    control: (base, state) => ({
      ...base,
      background: "#F74037",
      borderRadius: "5px",
      borderColor: "none",
      boxShadow: null,
      "&:hover": {
        borderColor: "#707070",
      },
      borderBottom: "1px solid #707070",
      borderTop: "none",
      borderLeft: "none",
      borderRight: "none",
    }),
    menuList: (styles) => ({
      ...styles,
      background: "#313131",
      color: "#fff",
      borderRadius: "5px",
    }),
    option: (styles, { isFocused, isSelected }) => {
      // Extract the background color logic into a separate statement
      let background;
      if (isFocused || isSelected) {
        background = "#656565";
      } else {
        background = undefined;
      }

      return {
        ...styles,
        background,
        zIndex: 1,
        height: "5%",
        fontSize: "12px",
      };
    },
    menu: (base) => ({
      ...base,
      zIndex: 100,
    }),
    indicatorsContainer: (prevStyle, state) =>
      state.isMulti
        ? {
            ...prevStyle,
            display: "none",
          }
        : null,
    input: (styles) => ({ ...styles, color: "#fff", padding: "0rem" }),
    placeholder: (styles) => ({
      ...styles,
      background: "#F74037",
      margin: "0 10px",
      color: "#fff",
      fontSize: "clamp(12px, 4vw, 14px)",
    }),
    valueContainer: (styles) => ({ ...styles, padding: "0rem" }),
    multiValue: (styles) => ({
      ...styles,
      background: "#707070",
      color: "#fff",
    }),
    multiValueLabel: (styles) => ({ ...styles, color: "#fff" }),
  };

  return (
    <div className={classes.NoOfViews}>
      <div className={classes.noViews2}>
        <span className={classes.viewText}>No. of views</span>
        <Select
          name="viewSelect"
          id="viewSelect"
          placeholder="Last Week"
          styles={selectStyle2}
          isSearchable={false}
        />
      </div>
      <Line data={lineGraphData} options={lineGraphOptions} />
    </div>
  );
};

export default NoOfClicks;
