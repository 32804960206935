import VideoCarousel from "../../../components/ui/VideoCarousel";
import useGetWelcomeVideos from "../../../hooks/useGetWelcomeVideos";
import PropTypes from "prop-types";
import React from "react";

const Genre = (props) => {
  const data = useGetWelcomeVideos({
    page: 1,
    limit: 10,
    genre: props.genre,
    type: "viral",
  });
  return (
    <VideoCarousel
      data={{ list: data }}
      title={`${props.genre}`}
      keyType={"genre"}
    />
  );
};

Genre.propTypes = {
  genre: PropTypes.string,
};

export default Genre;
