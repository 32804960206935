import Button from "../ui/Buttons";
import { useNavigate, useLocation } from "react-router-dom";
import classes from "./AuthCard.module.css";
import artistTrackImg from "../../assets/images/svg/audio-track.svg";
import listenerTrackImg from "../../assets/images/svg/listenerTrack.svg";
import React, { useState, useEffect } from "react";
import SignForm from "../forms/SignForm";
import PropTypes from "prop-types";

const AuthCard = ({ type, action, setForgotPassword, handleAction }) => {
  const Navigate = useNavigate();
  const location = useLocation();
  const [button, setButton] = useState("signin");

  useEffect(() => {
    if (location?.state?.key) setButton(location.state.key);
    else setButton("signin");
  }, [location]);

  return (
    <div className={`row ${classes.authCard}`}>
      {location.pathname !== "/signIn" && (
        <img
          src={type === "artist" ? artistTrackImg : listenerTrackImg}
          alt="audio-track"
          className={classes.cardImg}
        />
      )}
      {type && action ? (
        <SignForm
          action={action}
          role={type}
          setForgotPassword={setForgotPassword}
          handleAction={handleAction}
        />
      ) : (
        <>
          <div style={{ paddingBottom: "1rem" }}>
            <h1 className={classes.authcardheading}>
              I am {type === "artist" ? "an " : "a "}
              {type}
            </h1>
            {type === "artist" ? (
              <>
                <h1 className={classes.heading}>I create original music</h1>
                <h1 className={classes.heading}>I perform covers</h1>
              </>
            ) : (
              <>
                <h1 className={classes.heading}>I love music</h1>
                <h1 className={classes.heading}>I follow artists</h1>
              </>
            )}
          </div>

          <Button
            label="sign in"
            handleClick={() => {
              Navigate("/signIn", { state: { key: type, action: "signin" } });
              setButton("signin");
            }}
            className={`cardButton ${
              button === "signin" ? "active" : "inActive"
            }`}
          />
          <Button
            label="sign up"
            handleClick={() => {
              Navigate("/signIn", { state: { key: type, action: "signup" } });
              setButton("signup");
            }}
            className={`cardButton ${
              button === "signup" ? "active" : "inActive"
            }`}
          />
        </>
      )}
    </div>
  );
};

AuthCard.propTypes = {
  type: PropTypes.string,
  action: PropTypes.string,
  setForgotPassword: PropTypes.func,
  handleAction: PropTypes.func,
};

export default AuthCard;
