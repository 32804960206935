import React from "react";
import classes from "./Button.module.css";
import PropTypes from "prop-types";

const Button = ({
  label,
  handleClick,
  handleDrop,
  handleDragOver,
  handleDragEnter,
  handleMouseEnter,
  handleMouseLeave,
  className,
  toggle,
  disable,
  backImg,
}) => {
  // Split the className string into an array of class names
  let classNames = "";
  if (className !== undefined && className !== null && className !== "") {
    classNames = className
      .split(" ")
      .map((cn) => classes[cn])
      .join(" ");
  }

  return (
    <button
      className={classNames}
      style={{
        textTransform: "uppercase",
        cursor: "pointer",
        backgroundImage: backImg?.length ? backImg : "",
      }}
      onClick={handleClick}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      data-bs-toggle={toggle} // Add the data-bs-toggle attribute
      disabled={disable}
    >
      {label}
    </button>
  );
};

Button.propTypes = {
  label: PropTypes.any,
  handleClick: PropTypes.func,
  className: PropTypes.string,
  toggle: PropTypes.string,
  disable: PropTypes.bool,
  backImg: PropTypes.string,
  handleDrop: PropTypes.func,
  handleDragOver: PropTypes.func,
  handleDragEnter: PropTypes.func,
  handleMouseEnter: PropTypes.func,
  handleMouseLeave: PropTypes.func,
};

export default Button;
