import classes from "../AdDemographics.module.css";
import Card from "./card";
import ClicksCountry from "./clicksCountry";
import NoOfViews from "./noOfViews";
import PieChart from "./pieChart";
import NoOfClicks from "./noOfClicks";
import ClicksGenre from "./clicksGenre";
import React from "react";

const AdDemographicsAnalytics = () => {
  return (
    <div className="col-12 d-flex gap-3 pb-5 justify-content-center">
      <div className={`${classes.box1} gap-3 col-6`}>
        <Card />
        <NoOfViews />
        <NoOfClicks />
      </div>
      <div className={`${classes.box1} gap-3 col-5`}>
        <ClicksCountry />
        <PieChart />
        <ClicksGenre />
      </div>
    </div>
  );
};

export default AdDemographicsAnalytics;
