import { createReducer } from '@reduxjs/toolkit';
import {
    setUsername,
    updateUserProfile,
    setUserProfile,
    setClearUser,
    setProfileExists,
    setUserSearchHistory,
    setSearchSuggestionList,
    setUserId,
    setUserProfileFollowers,
    setUserPrivacyStore,
    setUserNotificationStore,
} from './actions';

const initialState = {
    username: '',
    name: '',
    email: '',
    linkedAccount: '',
    bandMembers: [],
    referralCode: '',
    id: '', // user id
    userId: '', // user id
    userProfileId: '',
    tokenExpiresAt: '',
    userProfileExists: false,
    userProfile: {},
    userSearchHistory: [],
    searchSuggestionList: [],
    totalFollowers: 0,
}

export default createReducer(initialState, (builder) => {
    builder
        .addCase(setUsername, (state, { payload: { username } }) => {
            state.username = username;
        })
        .addCase(updateUserProfile, (state, { payload: { data } }) => {
            state.name = data?.name ? data.name : '';
            state.email = data?.email ? data.email : '';
            state.linkedAccount = data?.linkedAccount ? data.linkedAccount : '';
            state.bandMembers = data?.bandMembers ? data.bandMembers : [];
            state.referralCode = data?.referralCode ? data.referralCode : '';
            state.id = data?._id ? data._id : '';
            state.tokenExpiresAt = data?.tokenExpiresAt ? data.tokenExpiresAt : '';
            state.userProfileExists = data?.userProfileExists ? data.tokenExpiresAt : '';
        })
        .addCase(setUserProfile, (state, { payload: { userProfile } }) => {
            state.userProfile = userProfile;
        })
        .addCase(setUserPrivacyStore, (state, { payload: { userPrivacy } }) => {
            state.userProfile.privacy = userPrivacy;
        })
        .addCase(setUserNotificationStore, (state, { payload }) => {
            // console.log('userNotification', payload);
            payload?.trivia &&
                (state.userProfile.notification.trivia = payload.trivia);
            payload?.gen &&
                (state.userProfile.notification.gen = payload.gen);
        })
        .addCase(setClearUser, (state) => {
            state.username = '';
            state.name = '';
            state.email = '';
            state.linkedAccount = '';
            state.bandMembers = [];
            state.referralCode = '';
            state.id = '';
            state.tokenExpiresAt = '';
            state.userProfileExists = false;
            state.userProfile = {};
            localStorage.clear();
        })
        .addCase(setProfileExists, (state, { payload: { userProfileExists } }) => {
            // console.log('userProfileExists ------------>>> ', userProfileExists);
            state.userProfileExists = userProfileExists;
        })
        .addCase(setUserSearchHistory, (state, { payload: { userSearchHistory } }) => {
            state.userSearchHistory = userSearchHistory;
        })
        .addCase(setSearchSuggestionList, (state, { payload: { searchSuggestionList } }) => {
            state.searchSuggestionList = searchSuggestionList;
        })
        .addCase(setUserId, (state, { payload: { userId } }) => {
            state.userId = userId;
        })
        .addCase(setUserProfileFollowers, (state, { payload: { followers } }) => {
            // console.log('setUserProfileFollowers -------- ', followers);
            state.totalFollowers = followers;
        })
})
