import classes from "./SeeMoreOption.module.css";
import React from "react";
import PropTypes from "prop-types";
import Button from "../Buttons";

const SeeMoreOptionUI = ({ handleClick }) => {
  const elem = (
    <div className="d-flex align-items-center gap-2">
      <span className={classes.seeMore}>See more</span>{" "}
      <i className={`fas fa-arrow-right ${classes.arrowRight}`}></i>
    </div>
  );
  return (
    <div
      className="mb-2 d-flex align-items-center justify-content-end w-100 gap-2"
      style={{ cursor: "pointer" }}
    >
      {/* See more{" "} */}
      <Button
        className={"blank"}
        handleClick={() => {
          handleClick();
        }}
        label={elem}
      />
    </div>
  );
};

SeeMoreOptionUI.propTypes = {
  handleClick: PropTypes.func,
};

export default SeeMoreOptionUI;
