import React, { useEffect, useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import PropTypes from "prop-types";

const AdCarousel = ({ data }) => {
  const [adData, setAdData] = useState([]);

  useEffect(() => {
    data?.list && setAdData(data.list);
  }, [data]);

  return (
    <Carousel>
      {adData.map((item, index) => (
        <Carousel.Item key={"test_" + item.advertiseInfo._id}>
          <img
            src={item.advertiseInfo.adFileUrl + "adfe"}
            className="d-block w-100"
            style={{
              height: "21.563rem",
              borderRadius: "1.5rem",
              objectFit: "cover",
            }}
            alt={`Slide ${index + 1}`}
          />
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

AdCarousel.propTypes = {
  data: PropTypes.object,
};

export default AdCarousel;
