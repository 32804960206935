import VideoCarousel from "../../components/ui/VideoCarousel";
import swipeUp2 from '../../assets/images/svg/RadarPage/swipeUp2.svg';
import React from 'react';

const SwipeUpCaraousal = () => {
    const dummySet = Array.from({ length: 10 }, (_, index) => (
        {
            videoInfo: {
                thumbnail: swipeUp2
            }
        }
    ))
    return (
        <>
            <div style={{ borderBottom: '1px solid #535050' }}></div>
            <VideoCarousel data={{ list: dummySet }} img={swipeUp2} title={'swipe up'} keyType={'swipeUpcomponent'} />
            <div style={{ borderBottom: '1px solid #535050', marginRight: '1rem' }}></div>
        </>
    )
}

export default SwipeUpCaraousal