import React, { useEffect, useState, useRef } from "react";
import SocketIO from "../../services/socketIO/SocketIO";

const GoLivePoc = () => {
  const videoRef = useRef(null);
  const [stream, setStream] = useState(null);
  const [isCameraOn, setIsCameraOn] = useState(false);
  const [isMicOn, setIsMicOn] = useState(false);

  // Start camera
  const startCamera = async () => {
    try {
      const mediaStream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: isMicOn,
      });

      // Set the stream to state
      setStream(mediaStream);

      // Display the video stream in the video element
      if (videoRef.current) {
        videoRef.current.srcObject = mediaStream;
      }

      // Update state to indicate camera is on
      setIsCameraOn(true);
    } catch (err) {
      console.error("Error accessing media devices:", err);
    }
  };

  // Start microphone
  const startMic = async () => {
    try {
      if (stream) {
        // If stream is already active, update audio tracks
        const audioStream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });

        // Add audio tracks to existing stream
        audioStream.getTracks().forEach((track) => {
          stream.addTrack(track);
        });

        // Update state to indicate mic is on
        setIsMicOn(true);
      } else {
        // If stream is not active, start both camera and mic
        await startCamera();
        const audioStream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });

        // Add audio tracks to existing stream
        audioStream.getTracks().forEach((track) => {
          stream.addTrack(track);
        });

        setIsMicOn(true);
      }
    } catch (err) {
      console.error("Error accessing microphone:", err);
    }
  };

  // Stop camera
  const stopCamera = () => {
    if (stream) {
      stream.getVideoTracks().forEach((track) => track.stop());
      if (videoRef.current) {
        videoRef.current.srcObject = null;
      }
      setIsCameraOn(false);
      checkAndStopStream();
    }
  };

  // Stop microphone
  const stopMic = () => {
    if (stream) {
      stream.getAudioTracks().forEach((track) => track.stop());
      setIsMicOn(false);
      checkAndStopStream();
    }
  };

  // Check if streaming should be stopped
  const checkAndStopStream = () => {
    if (!isCameraOn && !isMicOn && stream) {
      setStream(null);
    }
  };

  useEffect(() => {
    // if (stream) {
    SocketIO(stream);
    // <SocketIO stream={stream} />;
    // SocketIO(stream)
    // }
  }, [stream]);

  return (
    <>
      <h1 className="text-center">GoLivePoc</h1>
      <div>
        <h1>Camera and Microphone Control</h1>
        <video ref={videoRef} autoPlay playsInline>
          <track default kind="captions" src="" />
        </video>
        <br />
        <button onClick={startCamera} disabled={isCameraOn}>
          Start Camera
        </button>
        <button onClick={stopCamera} disabled={!isCameraOn}>
          Stop Camera
        </button>
        <br />
        <button onClick={startMic} disabled={isMicOn}>
          Start Microphone
        </button>
        <button onClick={stopMic} disabled={!isMicOn}>
          Stop Microphone
        </button>
      </div>
    </>
  );
};

export default GoLivePoc;
