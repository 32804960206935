import React from "react";
import { useLocation, Outlet, Navigate } from "react-router-dom";
import { useGetAccessToken } from "../store/redux/token/hooks";
import { useGetRole } from "../store/redux/application/hooks";
import ROUTES from "../constants/routes-role";
import { setClearToken } from "../store/redux/token/actions";
import { setClearApplication } from "../store/redux/application/actions";
import { setClearUser } from "../store/redux/user/actions";
import { useDispatch } from "react-redux";

// const ProtectedRoutes = () => {
//   const dispatch = useDispatch();
//   const accessToken = useGetAccessToken();
//   const Role = useGetRole();
//   const location = useLocation();

//   // Function to clear all tokens and state
//   const clearAll = () => {
//     dispatch(setClearToken());
//     dispatch(setClearApplication());
//     dispatch(setClearUser());
//   };

//   // Check if the current route is authorized based on role and access token
//   if (accessToken && Role) {
//     for (const object of ROUTES) {
//       if (
//         location.pathname.split("/").includes(object.path) ||
//         location.pathname === object.path
//       ) {
//         if (object.role.includes(Role)) {
//           if (
//             accessToken &&
//             accessToken !== null &&
//             accessToken !== undefined
//           ) {
//             // If authorized, render the child components
//             return <Outlet />;
//           } else {
//             // If not authorized, clear state and navigate to the root path
//             clearAll();
//             return (
//               <Navigate to="/" replace={true} state={{ from: location }} />
//             );
//           }
//         } else {
//           return (
//             <Navigate to="/radar" replace={true} state={{ from: location }} />
//           );
//         }
//       }
//     }
//   } else {
//     // If no access token or role, clear state and navigate to the root path
//     clearAll();
//     return (
//       <Navigate
//         to="/"
//         replace={true}
//         state={{ from: location, message: "Access Denied" }}
//       />
//     );
//   }

//   // If no route matches, navigate to the not-found page
//   return <Navigate to="/not-found" replace={true} state={{ from: location }} />;
// };

const findMatchingRoute = (routes, pathname, role) => {
  return routes.find(
    (route) =>
      (pathname.split("/").includes(route.path) || pathname === route.path) &&
      route.role.includes(role)
  );
};

const ProtectedRoutes = () => {
  const dispatch = useDispatch();
  const accessToken = useGetAccessToken();
  const Role = useGetRole();
  const location = useLocation();

  // Function to clear all tokens and state
  const clearAll = () => {
    dispatch(setClearToken());
    dispatch(setClearApplication());
    dispatch(setClearUser());
  };

  // If no access token or role, clear state and redirect to login
  if (!accessToken || !Role) {
    clearAll();
    return (
      <Navigate
        to="/"
        replace={true}
        state={{ from: location, message: "Access Denied" }}
      />
    );
  }

  // Find a matching route
  const matchedRoute = findMatchingRoute(ROUTES, location.pathname, Role);

  if (matchedRoute) {
    if (accessToken) {
      // Authorized access
      return <Outlet />;
    } else {
      // Clear state and redirect if token is invalid
      clearAll();
      return <Navigate to="/" replace={true} state={{ from: location }} />;
    }
  }

  // If no matching route, navigate to the not-found page
  return <Navigate to="/not-found" replace={true} state={{ from: location }} />;
};

export default ProtectedRoutes;
