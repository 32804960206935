// import Modal from "../../components/ui/Modal";
import React, { useState } from "react";
import ForgotPasswordForm from "../../components/forms/ForgotPasswordForm";
import BootstrapModal from "../../components/ui/Bootstrap/modal";

const ForgotPassword = () => {
  const [showModal, setShowModal] = useState(true);
  return (
    // <Modal
    //   shouldShow={showModal}
    //   onRequestClose={() => {
    //     setShowModal((prev) => !prev);
    //   }}
    //   children={<ForgotPasswordForm />}
    // />
    <BootstrapModal
      show={showModal}
      handleClose={() => {
        setShowModal((prev) => !prev);
      }}
      body={<ForgotPasswordForm />}
      className={"black-modal"}
      size={"md"}
      showHeader={false}
      allowBackdropClose={true}
    />
  );
};

export default ForgotPassword;
