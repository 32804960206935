import moment from 'moment';

export const facebookProfileUrlRegex = /^(https?:\/\/)?(www\.)?facebook\.com\/[a-zA-Z0-9.]+$/;
export const instagramProfileUrlRegex = /(?:(?:http|https):\/\/)?(?:www.)?(?:instagram.com|instagr.am|instagr.com)\/(\w+)/;
export const youtubeVideoUrlRegex = /^((https?:)?\/\/)?(www\.|m\.)?(youtube(-nocookie)?\.com|youtu\.be)\/([\w-]+(\?v=|\/embed\/|\/v\/)?[\w-]*)$/;
export const twitterProfileUrlRegex = /(https:\/\/twitter.com\/(?!\w+\/)(\w+))/;
export const spotifyProfileUrlRegex = /^(?:spotify:|https:\/\/[a-z]+\.spotify\.com\/(track\/|user\/(.*)\/playlist\/))(.*)$/
export const tiktokProfileUrlRegex = /(@[a-zA-Z0-9]*|.*)(\/.*\/|trending.?shareId=)([0-9]*)/;
export const websiteRegex = /^((http|https):\/\/)?(www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z]{1,3})+(\/[\w-]*)*\/?$/;

// export const checkEmail = (email) => {
//     const pattern = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
//     const result = pattern.test(email) || email.length === 0;
//     return result;
// }

export const checkEmail = (email) => {
    // Regex to ensure the email has a valid format and the part before '@' is not just numbers
    const pattern = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
    // Extract the part before '@'
    const localPart = email.split('@')[0];
    // Check if the local part contains at least one alphabetic character
    const hasAlphabet = /[a-zA-Z]/.test(localPart);
    // Validate email format and check if it contains an alphabet before '@'
    const result = (pattern.test(email) && hasAlphabet) || email.length === 0;
    return result;
};

export const comparePassword = (password, confirmPassword) => {
    return password === confirmPassword;
}

export const handleCheckName = (name) => name?.length < 3;

export const handleUsername = (username) => {

    if (username.length < 16) {
        const data = username.substring(0, 15);
        const trimmedUsername = data.replace(/\s/g, ''); // Remove spaces
        return trimmedUsername;
    }
    // else {
    //     const trimmedUsername = username.replace(/\s/g, ''); // Remove spaces
    //     return trimmedUsername;
    // }
}

export const handleName = (name) => {
    if (name.length === 30) {
        return name.substring(0, 29)
    }
    else {
        return name
    }
}

export const handlePassword = (password) => {
    if (password.length === 15) {
        return password.substring(0, 15)
    }
    else {
        return password
    }
}

export const signUpValidation = (name, email, password, confirmPassword) => {
    const errors = []
    if (handleCheckName(name)) {
        errors.push({ name: 'Name should be between 3 to 30 characters' });
    }
    if (!checkEmail(email)) {
        errors.push({ email: 'Provide a valid email' });
    }
    if (handlePassword(password).length < 6) {
        console.log('In LL ');
        errors.push({ password: 'Provide a valid password' });
    }
    if (!comparePassword(password, confirmPassword)) {
        errors.push({ confirmPassword: 'Incorrect password' });
    }
    return errors
}

export const verifyEnteredLink = (newLinks) => {
    // Configuration for platform regex validations
    const linkValidators = {
        facebook: facebookProfileUrlRegex,
        instagram: instagramProfileUrlRegex,
        youtube: youtubeVideoUrlRegex,
        twitter: twitterProfileUrlRegex,
        tiktok: tiktokProfileUrlRegex,
        spotify: spotifyProfileUrlRegex,
    };

    // Error object
    const errors = {};

    // Iterate over platforms and validate links
    Object.keys(linkValidators).forEach((platform) => {
        const regex = linkValidators[platform];
        const link = newLinks[platform];
        if (link && !link.match(regex)) {
            errors[platform] = 'Invalid URL';
        }
    });

    return errors;
};


// export const momentFromNow = (date) => {
//     if (typeof (date) === "string") {
//         return moment(new Date(parseInt(date))).fromNow();
//     }
//     else if (typeof (date) === "number") {
//         return moment(new Date(date)).fromNow();
//     }
// }

// convert timestamp to date ex: 1Day ago, 2month ago, etc.
export const momentFromNow = (date) => {
    const isNum = !isNaN(date) && !isNaN(parseFloat(date));

    if (!isNum) {
        const jsDate = new Date(date);
        const format2 = moment(jsDate).fromNow();
        return format2;
    } else {
        // console.log('date -------->> ', date);
        return moment(parseInt(date)).fromNow();
    }
    // return "Invalid date";
};

// convert timestamp to date
export const formatTimeData = (time) => {
    if (typeof time === 'string') {
        return moment(parseInt(time)).format('DD-MM-YYYY, h:mm A');
    } else if (typeof time === 'number') {
        return moment(time).format('DD-MM-YYYY, h:mm A');
    }
}

export const blobToBinary = (dataUrl) => {
    let BASE64_MARKER = ';base64,';
    if (dataUrl.indexOf(BASE64_MARKER) === -1) {
        let parts = dataUrl.split(',');
        let contentType = parts[0].split(':')[1];
        let raw = decodeURIComponent(parts[1]);
        return new Blob([raw], { type: contentType });
    }

    let parts = dataUrl.split(BASE64_MARKER);
    let contentType = parts[0].split(':')[1];
    let raw = window.atob(parts[1]);
    let rawLength = raw.length;

    let uInt8Array = new Uint8Array(rawLength);

    for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
    }

    let blobimage = new Blob([uInt8Array], { type: contentType })

    const fileName = 'image.jpg';
    const binaryFile = new File([blobimage], fileName, { type: blobimage.type });
    return binaryFile;
}

// export const nFormatter = (num, digits) => {
//     const lookup = [
//         { value: 1, symbol: "" },
//         { value: 1e3, symbol: "k" },
//         { value: 1e6, symbol: "M" },
//         { value: 1e9, symbol: "G" },
//         { value: 1e12, symbol: "T" },
//         { value: 1e15, symbol: "P" },
//         { value: 1e18, symbol: "E" }
//     ];
//     const regexp = /\.0+$|(?<=\.[0-9]*[1-9])0+$/;
//     const item = lookup.findLast(item => num >= item.value);
//     return item ? (num / item.value).toFixed(digits).replace(regexp, "").concat(item.symbol) : "0";
// }
export const nFormatter = (num, digits) => {
    const lookup = [
        { value: 1, symbol: "" },
        { value: 1e3, symbol: "k" },
        { value: 1e6, symbol: "M" },
        { value: 1e9, symbol: "G" },
        { value: 1e12, symbol: "T" },
        { value: 1e15, symbol: "P" },
        { value: 1e18, symbol: "E" }
    ];
    // Simplified regex to remove unnecessary zeroes without lookbehind
    const regexp = /\.([\d]*[\d])?0+$/;
    const item = lookup.slice().reverse().find(item => num >= item.value);
    return item ? (num / item.value).toFixed(digits).replace(regexp, "$1").concat(item.symbol) : "0";
};

export const userLoginSocial = (payload) => {
    const accessToken = new URLSearchParams(payload).get('access_token');
    const refreshToken = new URLSearchParams(payload).get('refresh_token');
    const userProfileExists = new URLSearchParams(payload).get('userProfileExists');
    const userId = new URLSearchParams(payload).get('userId');
    const tokenExpiresAt = new URLSearchParams(payload).get('tokenExpiresAt');
    const role = new URLSearchParams(payload).get('role');

    return {
        accessToken,
        refreshToken,
        userProfileExists,
        userId,
        tokenExpiresAt,
        role
    }
}

export const enums = {
    roles: {
        ADMIN: 'admin',
        USER: 'user',
        BAND: 'band'
    },
    FOLLOW: 'follow',
    UNFOLLOW: 'unFollow',
    REPORT_TYPE_USERPROFILE: 'userProfile',
    USER_PRIVACY_PUBLIC: 'Public',
    USER_PRIVACY_PRIVATE: 'Private',
}