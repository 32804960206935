import adartist from "../../../assets/images/svg/LandingPage/svg/landingAd.svg";
import AdCarousel from "../../../components/ui/AdCarousel";
import React from "react";

const AdvertisementComponent = () => {
  const dummyData = {
    list: [
      {
        advertiseInfo: {
          _id: "660a545388f6fa7082e1866c",
          adTitle: "banner",
          targetPath: "www.google.com",
          adFileUrl: `${adartist}`,
          adFileType: "image",
          videoId: "1397757887894053615",
          budget: 500,
          isExpire: false,
          status: "active",
          no_of_click: 5,
          no_of_view: 0,
          no_of_impression: 0,
          current_day_amount: 4.549999999999999,
        },
        creatorInfo: null,
      },
    ],
  };
  return <AdCarousel data={dummyData} img={adartist} />;
};

export default AdvertisementComponent;
