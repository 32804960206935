import classes from "./Form.module.css";
import PropTypes from "prop-types";
import React from "react";

const CheckBoxCustom = ({ option, onSelect, checked, className, disable }) => {
  return (
    <label className={`${classes.container} ${classes?.[className]}`}>
      {/* <input type="radio" checked={checked} name={'radio'} onChange={onSelect} disabled={disable} /> */}
      <input
        type="checkbox"
        id={option}
        name={option}
        value={option}
        onChange={onSelect}
      />
      <span className={classes.checkmark}></span>
      {option}
    </label>
  );
};

CheckBoxCustom.propTypes = {
  option: PropTypes.string,
  onSelect: PropTypes.func,
  checked: PropTypes.bool,
  className: PropTypes.string,
  disable: PropTypes.bool,
};

export default CheckBoxCustom;
