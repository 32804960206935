import React from "react";
import { Route, Routes } from "react-router-dom";
import AppRoutes from "./routes/AppRoutes";
import AuthPage from "./pages/AuthPage/LoginSingup";
import SignInAs from './pages/AuthPage/signInAs';
import LandingPage from './pages/LandingPage';
import NotFoundPage from './pages/NotFoundPage';
import ProtectedRoutes from './routes/ProtectedRoutes';
import PublicAuth from './routes/PublicAuth';

const Navigation = () => {
    return (
        <Routes>
            <>
                <Route element={<PublicAuth />}>
                    <Route path="/" element={<LandingPage />} />
                    <Route path="/signInAs" element={<SignInAs />} />
                    <Route path="/signUp" element={<AuthPage />} />
                    <Route path="/signIn" element={<AuthPage />} />
                    <Route path="/not-found" element={<NotFoundPage />} />
                </Route>

                <Route element={<ProtectedRoutes />}>
                    <Route path="*" element={<AppRoutes />} />
                </Route>
            </>
            {/* Temporary routes */}
            {/* <Route path="/temporary" element={<TemporaryPage />} /> */}
        </Routes>
    );
}

export default Navigation;
