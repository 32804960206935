import React, { useEffect, useRef } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import "./video-js.css";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import PropTypes from "prop-types";

const VideoJs = (props) => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);
  const {
    options,
    onReady,
    showShareButton,
    showAddWatchLaterButton,
    showNextButton,
  } = props;
  const navigate = useNavigate(); // Initialize navigate

  useEffect(() => {
    if (!playerRef.current) {
      const videoElement = document.createElement("video-js");
      videoElement.classList.add("vjs-big-play-centered");
      videoElement.classList.add("vjs-altRadar");
      videoRef.current.appendChild(videoElement);

      const player = (playerRef.current = videojs(
        videoElement,
        {
          ...options,
          controlBar: {
            children: [
              "playToggle", // Play/Pause button
              showNextButton === true && "nextButton", // Custom button
              "volumePanel",
              "currentTimeDisplay",
              "timeDivider",
              "durationDisplay",
              "progressControl",
              "pictureInPictureToggle",
              "fullscreenToggle",
            ],
          },
        },
        () => {
          onReady?.(player);

          // Create a custom button
          const Button = videojs.getComponent("Button");

          if (showNextButton) {
            const nextButton = new Button(player, {
              text: "next button",
            });

            nextButton.addClass("vjs-next-button");
            nextButton.controlText("Custom Button");
            nextButton.on("click", () => {
              alert("Next button clicked!");
            });

            // Add the custom button to the control bar at the second index
            const controlBar = player.getChild("controlBar");
            controlBar.addChild(nextButton, {}, 1); // Position at the second index
          }

          // Share button
          if (showShareButton) {
            // Share button
            const shareButton = new Button(player, {
              /* options */
            });

            shareButton.addClass("vjs-share-button");
            shareButton.on("click", () => {
              if (player.isFullscreen()) {
                player.exitFullscreen();
              }
              props.shareVideo();
            });

            // Add the custom button to the player element
            player.el().appendChild(shareButton.el());
            shareButton.addClass("vjs-share-button-top-right");
          }

          // Watch Later button
          if (showAddWatchLaterButton) {
            const watchLaterButton = new Button(player, {
              /* options */
            });

            watchLaterButton.addClass("vjs-watchlater-button");
            watchLaterButton.on("click", () => {
              if (player.isFullscreen()) {
                player.exitFullscreen();
              }
              // alert('Watch Later button clicked!');
              props.addWatchLater();
            });

            // Add the custom button to the player element
            player.el().appendChild(watchLaterButton.el());
            watchLaterButton.addClass("vjs-watchlater-button-top-right");
          }
        }
      ));

      if (props.path === "/") {
        // Redirect after 15 seconds
        setTimeout(() => {
          player.pause(); // Optional: pause the video before redirecting
          navigate("/signIn"); // Replace '/next-route' with your desired path}
        }, 20000); // 20000ms = 20 seconds
      }
    } else {
      const player = playerRef.current;
      player.addClass("vjs-altRadar");
      player.autoplay(options.autoplay);
      player.src(options.sources);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, onReady, navigate]);

  useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, []);

  return (
    <div data-vjs-player>
      <div ref={videoRef}></div>
    </div>
  );
};

VideoJs.propTypes = {
  options: PropTypes.object.isRequired,
  onReady: PropTypes.func,
  showShareButton: PropTypes.bool,
  showAddWatchLaterButton: PropTypes.bool,
  showNextButton: PropTypes.bool,
  shareVideo: PropTypes.func,
  addWatchLater: PropTypes.func,
  path: PropTypes.string,
};

export default VideoJs;
