import React from "react";
import { useLocation, Outlet, Navigate } from "react-router-dom";
import { useGetAccessToken } from "../store/redux/token/hooks";
import { useGetRole } from "../store/redux/application/hooks";
import PropTypes from "prop-types";

const PublicAuth = ({ children, ...rest }) => {
  const location = useLocation();
  const accessToken = useGetAccessToken();
  const Role = useGetRole();

  // Check if the current route is authorized based on role and access token
  if (accessToken && Role) {
    return <Navigate to="/radar" replace={true} state={{ from: location }} />;
  } else {
    return <Outlet />;
  }
};

PublicAuth.propTypes = {
  children: PropTypes.node,
};

export default PublicAuth;
