import React from 'react';
import classes from "./DeleteVidPromp.module.css";
import Button from "../../ui/Buttons";
import PropTypes from "prop-types";

const DeleteVidPromptComponent = ({
  videoName,
  handleCancel,
  handleDelete,
}) => {
  return (
    <>
      <div className="d-flex flex-column align-items-center p-2">
        <span className={classes.title}>Are You Sure ?</span>
        <span className={classes.title2}>
          Are you sure to delete {videoName} ?
        </span>
      </div>

      <div className="d-flex align-items-center justify-content-center p-2">
        <Button
          label={"Delete"}
          handleClick={handleDelete}
          className={"signin active"}
        />
        <Button
          label={"Cancel"}
          handleClick={handleCancel}
          className={"signin cancelTeal"}
        />
      </div>
    </>
  );
};

DeleteVidPromptComponent.propTypes = {
  videoName: PropTypes.string,
  handleCancel: PropTypes.func,
  handleDelete: PropTypes.func,
};

export default DeleteVidPromptComponent;
