import WelcomeBannerSection from "./WelcomeBannerSection";
import AdSection from "./AdvertisementSection";
import TrendingNow from "./TrendingNow";
import FreshFindsCover from "./FreshFindsCover";
import FreshFindsOrignal from "./FreshFindsOrignal";
import Viral from "./Viral";
import Currentcoversongsweeklycontest from "./Currentcoversongsweeklycontest";
import Currentoriginalsongsweeklycontest from "./Currentoriginalsongsweeklycontest";
import Weeklyoriginalsongcontestwinners from "./Weeklyoriginalsongcontestwinners";
import Weeklycoversongcontestwinners from "./Weeklycoversongcontestwinners";
import SwipeUp from "./SwipeUpSection";
import FooterSection from "./FooterSection";
import TopGenre from "./TopGenre";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { userLoginSocial } from "../../helpers/functions";
import {
  setAccessToken,
  setExpiryToken,
  setRefreshToken,
} from "../../store/redux/token/actions";
import { useDispatch } from "react-redux";
import { setRole } from "../../store/redux/application/actions";
import {
  setProfileExists,
  setUserId,
  setUserProfile,
} from "../../store/redux/user/actions";

const LandingPage = () => {
  const [locationSearch, setLocationSearch] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const page = 1;

  useEffect(() => {
    if (location?.search) {
      setLocationSearch(location?.search);
    } else {
      setLocationSearch("");
    }
  }, [location]);

  useEffect(() => {
    if (locationSearch.length) {
      const data = userLoginSocial(location.search);
      if (data) {
        dispatch(setAccessToken({ access_token: data?.accessToken }));
        dispatch(setRefreshToken({ refresh_token: data?.refreshToken }));
        dispatch(setExpiryToken({ expiry_token: data?.expiry }));
        dispatch(setUserProfile({ userProfile: { userId: data?.userId } }));
        dispatch(setUserId({ userId: data?.userId }));
        dispatch(
          setProfileExists({
            userProfileExists: JSON.parse(data?.userProfileExists),
          })
        );
        dispatch(setRole(data?.role));
        navigate("/radar");
      }
    }
  }, [locationSearch, dispatch, location.search, navigate]);

  if (!locationSearch.length) {
    return (
      <>
        <WelcomeBannerSection />
        <AdSection />
        <div style={{ padding: "0px 35px" }}>
          {/* Hide trivia and livestream sections for now */}
          {/* <TriviaSection /> */}
          {/* <LiveStreamSection page={page} /> */}
          <TrendingNow page={page} />
          <SwipeUp />
          <FreshFindsCover page={page} />
          <FreshFindsOrignal page={page} />
          <Currentcoversongsweeklycontest page={page} />
          <Currentoriginalsongsweeklycontest page={page} />
          <Weeklyoriginalsongcontestwinners page={page} />
          <Weeklycoversongcontestwinners page={page} />
          <Viral page={page} />
          <TopGenre />
        </div>
        <FooterSection />
      </>
    );
  } else {
    return null;
  }
};

export default LandingPage;
